import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Input,
  useDisclosure,
  Progress,
  Flex,
  // keyframes,
} from '@chakra-ui/react'

import { z } from 'zod'

import { useDownloadPeakHoursIntervalsReport } from '../models/services/downloadReportService'
import { isLoadingSignal } from 'features/prices/editUeSystemOperatorHours/signals/signals'

// misc
import { HiOutlineInboxArrowDown } from 'react-icons/hi2'
import { SiMicrosoftexcel } from 'react-icons/si'

interface DownloadReportProps {
  year: string
  isDisabled: boolean
  reportName: string
  price_zone_id?: string
  ue_system_id?: string
}

const schema = z.object({
  timeZone: z
    .string()
    .regex(/^-?\d+$/, 'Введите только числовое значение')
    .refine((val) => {
      const num = parseInt(val, 10)
      return num >= -11 && num <= 12
    }, 'Значение должно быть в диапазоне от -11 до 12'),
})

const reportNamesMap = {
  hours: 'часы в столбец',
  intervals: 'интервалы',
}

export const DownloadPeakHoursIntervalsReport: React.FC<
  DownloadReportProps
> = ({ isDisabled, year, price_zone_id, ue_system_id, reportName }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [value, setValue] = useState<string>('0')
  const [error, setError] = useState<string | null>(null)
  const [progress, setProgress] = useState<number>(1)
  const [opacity, setOpacity] = useState<number>(1)

  const { mutate: downloadPeakHoursIntervalsReport, isLoading } =
    useDownloadPeakHoursIntervalsReport()

  useEffect(() => {
    let blinkInterval: NodeJS.Timeout

    if (showLoader) {
      blinkInterval = setInterval(() => {
        setOpacity((prev) => (prev === 1 ? 0.5 : 1))
      }, 500)
    } else {
      setOpacity(1)
    }

    return () => clearInterval(blinkInterval)
  }, [showLoader])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)

    const result = schema.safeParse({ timeZone: event.target.value })

    if (!result.success)
      setError(result.error.errors[0]?.message || 'Ошибка валидации')
    else setError(null)
  }

  const handleDownloadReport = (report_type) => {
    onClose()
    isLoadingSignal.value = true

    let progressInterval

    if (report_type === 'hours') {
      progressInterval = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 95) clearInterval(progressInterval)
          return prevProgress + 8
        })
      }, 1000)
      setShowLoader(true)
    }

    downloadPeakHoursIntervalsReport({
      year,
      report_type,
      price_zone_id,
      ue_system_id,
      time_zone_by_msk: value,
      successAction: () => {
        onClose()
        setValue('0')
        isLoadingSignal.value = false
        if (report_type === 'hours') {
          setProgress(100)
          setShowLoader(false)
          clearInterval(progressInterval)
          setTimeout(() => {
            setValue('0')
            setProgress(1)
          }, 100)
        }
      },
      failureAction: () => {
        if (report_type === 'hours') {
          setProgress(100)
          setShowLoader(false)
          clearInterval(progressInterval)
          setTimeout(() => {
            setValue('0')
            setProgress(1)
          }, 100)
        }
        isLoadingSignal.value = false
      },
      reportName: `${reportName}`,
    })
  }

  return (
    <>
      <Popover
        placement="bottom-start"
        isOpen={isOpen}
        closeOnBlur={true}
        onClose={onClose}
      >
        <PopoverTrigger>
          <Box position={'relative'}>
            <Popover placement="bottom" isOpen={showLoader}>
              <PopoverTrigger>
                <Box position={'absolute'} top={'20px'} left={'40px'} />
              </PopoverTrigger>
              <PopoverContent boxShadow="lg" w={'220px'}>
                <PopoverHeader fontWeight="semibold" fontSize={'12px'}>
                  <Flex alignItems={'center'} flexDirection={'column'}>
                    <Box fontSize={'25px'}>
                      <HiOutlineInboxArrowDown />
                    </Box>
                    <Text textAlign={'center'}>
                      Идет формирование отчета, это займет некоторое время
                    </Text>
                  </Flex>
                </PopoverHeader>

                <PopoverArrow />
                <PopoverBody fontSize={'12px'}>
                  <Box>
                    <Progress
                      hasStripe
                      value={progress}
                      colorScheme={'green'}
                      sx={{
                        opacity: opacity,
                        transition: 'opacity 0.5s ease-in-out',
                      }}
                    />
                  </Box>
                </PopoverBody>
              </PopoverContent>
            </Popover>
            <Button
              mr="10px"
              position={'relative'}
              size={'xs'}
              display={'flex'}
              alignItems={'center'}
              onClick={onOpen}
              isDisabled={isDisabled}
              isLoading={isLoading}
              border={'1px solid #cfc6c6'}
              _hover={{ border: '1px solid #16b164' }}
            >
              <Box
                fontSize={'18px'}
                mr={'5px'}
                color={'green'}
                alignItems={'center'}
              >
                <SiMicrosoftexcel />
              </Box>
              <Text position={'relative'}> Скачать отчет</Text>
            </Button>
          </Box>
        </PopoverTrigger>
        <PopoverContent boxShadow="lg" w={'220px'}>
          <PopoverHeader fontWeight="semibold" fontSize={'12px'}>
            <Box>
              Часовой пояс: МСК{' '}
              <Input
                ml={'10px'}
                isInvalid={!!error}
                placeholder=""
                size={'xs'}
                w={'40px'}
                value={value}
                onChange={handleChange}
              />
              {error && <Text color="red.500">{error}</Text>}
            </Box>
          </PopoverHeader>

          <PopoverArrow />
          <PopoverCloseButton onClick={onClose} />
          <PopoverBody fontSize={'12px'}>
            <Box>
              <Button
                w={'100%'}
                position={'relative'}
                size={'xs'}
                display={'flex'}
                alignItems={'center'}
                isDisabled={isDisabled || !!error}
                // isLoading={isLoading}
                border={'1px solid #cfc6c6'}
                _hover={{ border: '1px solid #16b164' }}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  handleDownloadReport('intervals')
                }}
              >
                <Box
                  fontSize={'18px'}
                  mr={'5px'}
                  color={'green'}
                  position={'absolute'}
                  left={'5px'}
                >
                  <HiOutlineInboxArrowDown />
                </Box>
                <Text fontSize={'12px'} position={'relative'}>
                  Интервалы
                </Text>
              </Button>
              <Button
                mt={'5px'}
                w={'100%'}
                position={'relative'}
                size={'xs'}
                display={'flex'}
                alignItems={'center'}
                isDisabled={isDisabled || !!error}
                // isLoading={isLoading}
                border={'1px solid #cfc6c6'}
                _hover={{ border: '1px solid #16b164' }}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  handleDownloadReport('hours')
                }}
              >
                <Box
                  fontSize={'18px'}
                  mr={'5px'}
                  color={'green'}
                  position={'absolute'}
                  left={'5px'}
                >
                  <HiOutlineInboxArrowDown />
                </Box>
                <Text position={'relative'}>Часы в столбец</Text>
              </Button>
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  )
}
