import React, { memo } from 'react'
import { Box } from '@chakra-ui/react'

import { TableEntity } from './TableEntity'
import { DayZoneIntervalsTableRow } from '../../types/types'

interface UeSystemOperatorHoursTableProps {
  tableData: DayZoneIntervalsTableRow[]
  editMode: boolean
  setTableData: any
}

export const DayZoneIntervalsTable: React.FC<UeSystemOperatorHoursTableProps> =
  memo(
    ({ tableData, editMode, setTableData }): JSX.Element => {
      return (
        <Box margin={'-17px'} mt={'0px'}>
          <TableEntity
            tableData={tableData}
            editMode={editMode}
            setTableData={setTableData}
          />
        </Box>
      )
    },
    (prevProps, nextProps) => {
      return (
        prevProps.tableData === nextProps.tableData &&
        prevProps.editMode === nextProps.editMode
      )
    },
  )
