import { useEffect, useState } from 'react'
import { Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'

import { ImportData, ImportDataATC } from 'widgets'
import { useUrlParams } from 'shared/utils'

export const ImportDataPage: React.FC = (): React.ReactElement => {
  const { query_pageType, query_date } = useUrlParams()

  const [tabIndex, setTabIndex] = useState<number>(0)

  useEffect(() => {
    if (query_pageType === 'Загрузка с АТС') setTabIndex(1)
  }, [query_pageType])

  const handleTabsChange = (index) => setTabIndex(index)

  return (
    <Tabs index={tabIndex} onChange={handleTabsChange}>
      <TabList p={'10px'} pb={0}>
        <Tab>
          <Text fontSize={'18px'}>Импорт файлов</Text>
        </Tab>
        <Tab>
          <Text fontSize={'18px'}>Загрузка с АТС</Text>
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel padding={0}>
          <ImportData />
        </TabPanel>
        <TabPanel padding={0}>
          <ImportDataATC isActive={tabIndex === 1} query_date={query_date} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}
