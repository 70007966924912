import { useState } from 'react'
import { AxiosError } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'

import { queryKeys } from 'shared/constants'
import { ExtendedAxiosError } from 'shared/models'
import { PricesServiceService } from 'shared/services'
import { toasts } from 'shared/utils'

interface DeleteReport {
  successAction?: () => void
  failureAction?: () => void
  year: string
  report_type: string
  ue_system_id?: string
  price_zone_id?: string
}

export function useDeletePeakHoursIntervalsReport(): {
  mutate: UseMutateFunction<undefined, AxiosError, DeleteReport, unknown>
  isLoading: boolean
} {
  const queryClient = useQueryClient()
  const [isInvalidating, setIsInvalidating] = useState(false)

  const { mutate, isLoading: isMutating } = useMutation(
    ({ year, ue_system_id, price_zone_id, report_type }: DeleteReport) =>
      PricesServiceService.deletePeakHoursIntervalsReport({
        year,
        ue_system_id,
        price_zone_id,
      }),
    {
      onSuccess: async (response: undefined, variables: DeleteReport) => {
        if (variables.ue_system_id) {
          await queryClient.invalidateQueries(
            [
              queryKeys.prices.dayZoneIntervalsService,
              variables.ue_system_id,
              variables.year,
            ],
            { refetchInactive: true },
          )
        }

        if (variables.price_zone_id) {
          await queryClient.invalidateQueries(
            [
              queryKeys.prices.ueSystemOperatorHours,
              variables.price_zone_id,
              variables.year,
            ],
            { refetchInactive: true },
          )
        }

        if (variables.successAction) {
          variables.successAction()
          toasts.success({
            title: 'Успех',
            description: `Отчет успешно очищен`,
          })
        }
        setIsInvalidating(false)
      },
      onError: (error: ExtendedAxiosError, variables: DeleteReport) => {
        const errorMessage = error.response?.data.detail || 'Произошла ошибку'
        toasts.error({ title: 'Произошла ошибка', description: errorMessage })
        variables.failureAction()
      },
    },
  )

  return { mutate, isLoading: isMutating || isInvalidating }
}
