import { CellProps } from 'react-table'

import { BasicSumColumn, BasicTitle, BasicCommonColumn } from 'shared/ui'

export const TableStructure = () => {
  const size = 'lg'

  const structure = [
    {
      title: () => <BasicTitle size={size}>Субъект РФ</BasicTitle>,
      accessor: 'region_name',
      disableSortBy: true,
      minWidth: 120,
      width: 120,
      CellComponent: ({ row }: CellProps<any, string>) => {
        return (
          <BasicCommonColumn align={'center'} size={size}>
            {row.original.region_name}
          </BasicCommonColumn>
        )
      },
    },
    {
      title: () => (
        <BasicTitle size={size}>Тариф ФСК (потери), руб/МВт*ч</BasicTitle>
      ),
      accessor: 'tariff_fgc	',
      disableSortBy: true,
      minWidth: 40,
      width: 40,
      CellComponent: ({ row }: CellProps<any, string>) => {
        return (
          <BasicSumColumn size={size}>{row.original.tariff_fgc}</BasicSumColumn>
        )
      },
    },
  ]

  return structure
}
