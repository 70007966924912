export enum MonitoringType {
  byDeliveryZone,
  byMonth,
}

export interface CheckResult {
  name: string
  status: string
  message: string
  reference: Reference
}

export interface Params {
  date?: string
  gs_activity_areas_id?: string
  subject_id?: string
}

export interface Reference {
  link: string
  page: string
  params: Params
}

export interface ApiData {
  month: string
  gs_activity_areas_id: string
  check_result: CheckResult[]
  reference: Reference
}

export interface TransformedData {
  [data_type: string]: {
    [zone_id: string]: {
      status: string
      message: string
      reference: Reference
    }
  }
}
