import { signal } from '@preact/signals-react'
import { DayZoneIntervalsTableRow } from '../types/types'

export const tableDataSignal = signal<DayZoneIntervalsTableRow[]>()
export const tableUpdatedDataSignal = signal<
  {
    dz_intervals_id: string
    is_peak: boolean
  }[]
>([])
export const isDataChangedSignal = signal<boolean>(false)
export const isLoadingSignal = signal<boolean>(false)
