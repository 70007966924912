import { Box, Flex, Text } from '@chakra-ui/react'
import { DropzoneRootProps } from 'react-dropzone'

//  misc
import { BsCloudUpload } from 'react-icons/bs'
import { HiOutlineInboxArrowDown, HiInboxArrowDown } from 'react-icons/hi2'
import { ImDownload2 } from 'react-icons/im'
import { PiFileArrowDown } from 'react-icons/pi'

interface DropBoxProps {
  children: React.ReactNode
  getRootProps: (props?: DropzoneRootProps) => DropzoneRootProps
  isDragActive?: boolean
}

export const DropBox: React.FC<DropBoxProps> = ({
  children,
  getRootProps,
  isDragActive,
}) => {
  return (
    <Box
      cursor="pointer"
      position="relative"
      h="100px"
      border={isDragActive ? '2px dashed #1b5386' : '2px dashed #cfcfcf'}
      _hover={{
        border: '2px dashed #1b5386',
      }}
      transition="0.2s ease all"
      borderRadius="6px"
      role="group"
    >
      <Box
        {...getRootProps()}
        position="absolute"
        top={0}
        bottom={0}
        left={0}
        right={0}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Flex flexDirection="column" alignItems="center">
          <Box
            fontSize="40px"
            color={isDragActive ? '#1b5386' : '#cfcfcf'}
            transition="0.2s ease all"
          >
            {isDragActive ? <PiFileArrowDown /> : <HiOutlineInboxArrowDown />}
          </Box>
          <Text
            color={isDragActive ? '#1b5386' : '#cfcfcf'}
            transition="0.2s ease all"
          >
            Перетащите файлы сюда в формате .xlsx или .xls или кликните, чтобы
            выбрать и добавить.
          </Text>
        </Flex>
      </Box>
      {children}
    </Box>
  )
}
