import React, { useMemo } from 'react'
import { Box } from '@chakra-ui/react'
import { useWindowSize } from 'usehooks-ts'

import { ApiData, TransformedData } from 'widgets/prices/monitoring/types/types'

import { MonitoringByMonthTable } from './MonitoringByMonthTable'

const rightMarginOffset = 280

export const MonitoringByMonthDataView: React.FC<{ data: ApiData[] }> = ({
  data = [],
}) => {
  const { width } = useWindowSize()

  const transformedData: TransformedData = useMemo(() => {
    const result: TransformedData = {}

    data.forEach((item) => {
      const { month, check_result } = item
      check_result.forEach(({ name, status, message, reference }) => {
        if (!result[name]) {
          result[name] = {}
        }
        result[name][month] = { status, message, reference }
      })
    })

    return result
  }, [data])

  const months = useMemo(() => {
    return Array.from(new Set(data.map((item) => item.month))).sort()
  }, [data])

  return (
    <Box maxW={`${width - rightMarginOffset}px`}>
      <MonitoringByMonthTable data={transformedData} months={months} />
    </Box>
  )
}
