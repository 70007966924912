import { useMemo } from 'react'

type ReferenceParams = {
  query_subject_id?: string
  query_gs_activity_areas_id?: string
  query_date?: string
  query_pageType?: string
  query_price_zone_id?: string
  query_ue_system_id?: string
}

export function useUrlParams(): ReferenceParams {
  return useMemo(() => {
    const params = new URLSearchParams(window.location.search)
    const result: ReferenceParams = {}

    if (params.has('subject_id'))
      result.query_subject_id = params.get('subject_id') || undefined
    if (params.has('price_zone_id'))
      result.query_price_zone_id = params.get('price_zone_id') || undefined
    if (params.has('ue_system_id'))
      result.query_ue_system_id = params.get('ue_system_id') || undefined
    if (params.has('gs_activity_areas_id'))
      result.query_gs_activity_areas_id =
        params.get('gs_activity_areas_id') || undefined
    if (params.has('date')) result.query_date = params.get('date') || undefined
    if (params.has('pageType'))
      result.query_pageType = params.get('pageType') || undefined

    return result
  }, [window.location.search])
}
