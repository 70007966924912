import { AxiosError, AxiosResponse } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'

import { queryKeys } from 'shared/constants'
import {
  ExtendedAxiosError,
  UpdateDayZoneIntervalsRequest,
} from 'shared/models'
import { PricesServiceService } from 'shared/services'
import { toasts } from 'shared/utils'

interface UseUpdateDayZoneIntervalsMutation {
  successAction: () => void
  errorAction: () => void
  data: UpdateDayZoneIntervalsRequest[]
  ue_system_id: string
  date_period: string
}

export function useUpdateDayZoneIntervals(): {
  mutate: UseMutateFunction<
    AxiosResponse<undefined>,
    AxiosError,
    UseUpdateDayZoneIntervalsMutation,
    unknown
  >
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    ({ data, ue_system_id, date_period }: UseUpdateDayZoneIntervalsMutation) =>
      PricesServiceService.updateDayZoneIntervals({
        data,
      }),
    {
      onSuccess: (
        data: AxiosResponse<undefined>,
        variables: UseUpdateDayZoneIntervalsMutation,
      ) => {
        if (variables.successAction) {
          variables.successAction()
          toasts.success({
            title: 'Успех',
            description: `Таблица была успешно обновлена`,
          })
        }

        queryClient.invalidateQueries(
          [
            queryKeys.prices.dayZoneIntervalsService,
            variables.ue_system_id,
            variables.date_period,
          ],
          { refetchInactive: true },
        )
      },
      onError: (
        error: ExtendedAxiosError,
        variables: UseUpdateDayZoneIntervalsMutation,
      ) => {
        variables.errorAction()
        toasts.error({
          title: 'Произошла ошибка',
          description:
            error.response.data.detail || 'Таблица не была обновлена',
        })
      },
    },
  )
  return { mutate, isLoading }
}
