import { AxiosError, AxiosResponse } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'

import { queryKeys } from 'shared/constants'
import {
  UpdateMonitoringTemplateRequest,
  ExtendedAxiosError,
} from 'shared/models'
import { MonitoringPricesService } from 'shared/services'
import { toasts } from 'shared/utils'

interface UpdateMonitoringTemplateMutation
  extends UpdateMonitoringTemplateRequest {
  successAction: () => void
}

export function useUpdateMonitoringTemplate(): {
  mutate: UseMutateFunction<
    AxiosResponse<undefined>,
    AxiosError,
    UpdateMonitoringTemplateMutation,
    unknown
  >
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(
    ({
      monitoring_template_name,
      gs_activity_areas_ids,
      old_template_name,
      successAction,
    }: UpdateMonitoringTemplateMutation) =>
      MonitoringPricesService.updateMonitoringTemplates({
        monitoring_template_name,
        gs_activity_areas_ids,
        old_template_name,
      }),
    {
      onSuccess: (
        data: AxiosResponse<undefined>,
        variables: UpdateMonitoringTemplateMutation,
      ) => {
        if (variables.successAction) {
          variables.successAction()
          toasts.success({
            title: 'Успех',
            description: `Шаблон был успешно обновлен`,
          })
        }
        queryClient.invalidateQueries([queryKeys.prices.allMonitoringTemplates])
      },
      onError: (error: ExtendedAxiosError) =>
        toasts.error({
          title: 'Произошла ошибка',
          description: error.response.data.detail || 'Шаблон не был обновлен',
        }),
    },
  )
  return { mutate, isLoading }
}
