import React, { useMemo } from 'react'
import { Box } from '@chakra-ui/react'
import { MonitoringByActivityZoneTable } from './MonitoringByActivityZoneTable'
import { useWindowSize } from 'usehooks-ts'

import { useGetAllActivityAreas } from 'entities/referenceBooks'
import { ApiData, TransformedData } from 'widgets/prices/monitoring/types/types'

const rightMarginOffset = 280

export const MonitoringByActivityZoneDataView: React.FC<{
  data: ApiData[]
}> = ({ data = [] }) => {
  const { data: activityAreasData = [] } = useGetAllActivityAreas({
    enabled: true,
  })

  const { width } = useWindowSize()

  const transformedData: TransformedData = useMemo(() => {
    const result: TransformedData = {}

    data.forEach((item) => {
      const { gs_activity_areas_id: zone_id, check_result } = item
      check_result.forEach(({ name, status, message, reference }) => {
        if (!result[name]) {
          result[name] = {}
        }
        result[name][zone_id] = { status, message, reference }
      })
    })

    return result
  }, [data])

  const zoneNamesDictionary = useMemo(() => {
    return Object.fromEntries(
      activityAreasData.map((area) => [
        area.gs_activity_areas_id,
        area.area_name,
      ]),
    )
  }, [activityAreasData])

  const allZones = useMemo(
    () =>
      Array.from(new Set(data.map((item) => item.gs_activity_areas_id))).sort(),
    [data],
  )

  const zoneNames = allZones.map(
    (zone_id) => zoneNamesDictionary[zone_id] || zone_id,
  )

  return (
    <Box maxW={`${width - rightMarginOffset}px`}>
      <MonitoringByActivityZoneTable
        data={transformedData}
        zones={allZones}
        zoneNames={zoneNames}
      />
    </Box>
  )
}
