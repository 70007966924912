import { AxiosPromise } from 'axios'

import {
  CreateMonitoringTemplateRequest,
  GetMonitoringByZoneActivityRequest,
  GetMonitoringByZoneActivityResponse,
  GetMonitoringTemplateResponse,
  UpdateMonitoringTemplateRequest,
} from 'shared/models'

import { ServiceBase } from './serviceBase'

export class MonitoringPricesService extends ServiceBase {
  protected static BASE_PATH = 'monitoring_prices'

  public static createMonitoringTemplates({
    monitoring_template_name,
    gs_activity_areas_ids,
  }: CreateMonitoringTemplateRequest): AxiosPromise<CreateMonitoringTemplateRequest> {
    return this.post<CreateMonitoringTemplateRequest>(
      `${this.BASE_PATH}/create_monitoring_templates`,
      {
        monitoring_template_name,
        gs_activity_areas_ids,
      },
    )
  }

  public static getMonitoringTemplates(): AxiosPromise<
    GetMonitoringTemplateResponse[]
  > {
    return this.get<GetMonitoringTemplateResponse[]>(
      `${this.BASE_PATH}/list_monitoring_templates`,
    )
  }

  public static updateMonitoringTemplates({
    monitoring_template_name,
    gs_activity_areas_ids,
    old_template_name,
  }: UpdateMonitoringTemplateRequest): AxiosPromise<UpdateMonitoringTemplateRequest> {
    return this.patch<UpdateMonitoringTemplateRequest>(
      `${this.BASE_PATH}/update_monitoring_templates/${old_template_name}`,
      {
        monitoring_template_name,
        gs_activity_areas_ids,
      },
    )
  }

  public static deleteMonitoringTemplate({
    monitoring_template_name,
  }: {
    monitoring_template_name: string
  }): AxiosPromise<{ monitoring_template_name: string }> {
    return this.delete<undefined>(
      `${this.BASE_PATH}/update_monitoring_templates/${monitoring_template_name}`,
    )
  }

  public static getMonitoringByZoneActivity({
    generatedData,
  }: GetMonitoringByZoneActivityRequest): AxiosPromise<
    GetMonitoringByZoneActivityResponse[]
  > {
    return this.post<GetMonitoringByZoneActivityResponse[]>(
      `${this.BASE_PATH}/monitoring_by_gs_activity_areas`,
      {
        data: generatedData,
      },
    )
  }
}
