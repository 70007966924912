/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react'

import { BasicTable } from 'shared/ui'

import { TableStructure } from './TableStructure'

interface IPriceDetail {
  'СВНЦЭ РСВ'?: string
  'СВНЦЭ БР+'?: string
  'СВНЦЭ БР-'?: string
  'СВНЦЭ БР'?: string
}

interface IHourDataEntry {
  date: string
  hour_number: number
}

type TableDataItem = IHourDataEntry & IPriceDetail

interface ITableEntityProps {
  tableData: TableDataItem[]
}

export const TableEntity: React.FC<ITableEntityProps> = (props) => {
  const { tableData = [] } = props

  const columns = TableStructure()

  const data = useMemo(() => tableData, [tableData])

  const tableProps = useMemo(
    () => ({
      tableStyles: {
        tableMaxHeight: '65vh',
        tableWidth: '100%',
      },
      data,
      columns,
      emptyText: 'Данные отсутствуют',
      loading: false,
      showTableMenu: false,
      smallShadow: true,
      lightHeader: true,
      smallHeader: true,
      resizable: false,
    }),
    [data, columns],
  )

  return (
    <>
      <BasicTable {...tableProps} />
    </>
  )
}
