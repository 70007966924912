/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { CSSProperties, useState, useRef, useEffect } from 'react'
import clsx from 'clsx'

import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  ColumnGroup,
  HeaderGroup,
  ColumnInstance,
} from 'react-table'

import { Box, Flex, Text, Spinner, border } from '@chakra-ui/react'

import { ColumnProps, useColumns } from './UseColumns'
import { TableMenu } from './TableMenu'

//misc
import {
  HiOutlineChevronUp,
  HiArrowsUpDown,
  HiBarsArrowUp,
  HiBarsArrowDown,
} from 'react-icons/hi2'

import './table.css'

interface TableStyles {
  tableHeight?: string
  tableWidth?: string
  tableMaxHeight?: string
}

interface BasicTableProps {
  data: any
  emptyText?: string
  columns: ColumnProps[]
  tableStyles: TableStyles
  loading?: boolean
  showTableMenu?: boolean
  smallShadow?: boolean
  lightHeader?: boolean
  smallHeader?: boolean
  hiddenColumns?: string[]
  customHeader?: () => React.ReactNode
  resizable?: boolean
}

export const BasicTable: React.FC<BasicTableProps> = React.memo((props) => {
  const {
    data,
    columns,
    tableStyles,
    emptyText = 'Данные отсутствуют',
    loading = false,
    showTableMenu = true,
    smallShadow = false,
    lightHeader = false,
    smallHeader = false,
    hiddenColumns = [],
    customHeader = null,
    resizable = true,
  } = props

  const [showScrollTopButton, setShowScrollTopButton] = useState<boolean>(false)
  const tableRef = useRef<HTMLDivElement>(null)

  // const [tableWidth, setTableWidth] = useState(
  //   tableStyles?.tableWidth === '100%'
  //     ? window.innerWidth - 250
  //     : Math.min(
  //         window.innerWidth - 250,
  //         parseInt(tableStyles?.tableWidth || 'Infinity'),
  //       ),
  // )

  // useEffect(() => {
  //   const handleResize = () => {
  //     const maxTableWidth = parseInt(tableStyles?.tableWidth || 'Infinity')

  //     if (tableStyles?.tableWidth === '100%')
  //       setTableWidth(window.innerWidth - 250)
  //     else setTableWidth(Math.min(window.innerWidth - 250, maxTableWidth))
  //   }
  //   window.addEventListener('resize', handleResize)

  //   return () => {
  //     window.removeEventListener('resize', handleResize)
  //   }
  // }, [tableStyles?.tableWidth])

  const memoizedColumns = useColumns(columns)

  const tableContainerStyle: CSSProperties = {
    maxHeight: tableStyles?.tableMaxHeight || '300px',
    width: tableStyles?.tableWidth || '100%',
    // width: resizable ? `${tableWidth}px` : tableStyles?.tableWidth || '100%',
    // maxWidth: `${tableStyles?.tableWidth}`,
    overflow: 'auto',
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.15)',
    borderRadius: '8px',
    position: 'relative',
  }

  const initialState = hiddenColumns

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement
    const top = target.scrollTop > 300
    setShowScrollTopButton(top)
  }

  const scrollToTop = () => {
    if (tableRef.current) {
      tableRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  // const defaultColumn = useMemo(() => {
  //   return {
  //     Filter: ColumnFilter,
  //   };
  // }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    footerGroups,
    allColumns,
    getToggleHideAllColumnsProps,
    state,
    setGlobalFilter,
    setHiddenColumns,
  } = useTable(
    {
      columns: memoizedColumns as ColumnGroup[],
      data,
      initialState,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
  ) as any

  React.useEffect(() => {
    setHiddenColumns(hiddenColumns)
  }, [])

  const { globalFilter } = state

  if (loading) {
    return (
      <Flex align="center" justify="center" height="100%" minHeight="300px">
        <Spinner
          thickness="4px"
          speed="0.35s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Flex>
    )
  }

  if (!data.length)
    return (
      <Text
        fontSize={'16px'}
        color="gray.600"
        borderLeft="3px solid #319795"
        fontWeight={'normal'}
        w={'fit-content'}
        pl={'12px'}
      >
        {emptyText}
      </Text>
    )

  return (
    <Box position={'relative'}>
      {showTableMenu && (
        <TableMenu
          width={tableStyles?.tableWidth || '100%'}
          allColumns={allColumns}
          getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
          setGlobalFilter={setGlobalFilter}
          globalFilter={globalFilter}
        />
      )}
      <Box
        background={'#f3f3f3'}
        w={'100%'}
        h={'1px'}
        position={'absolute'}
        zIndex={1}
      />
      <div
        style={tableContainerStyle}
        // className="table-container"
        className={clsx('table-container', {
          smallShadow,
          lightHeader: lightHeader,
          smallHeader,
        })}
        onScroll={handleScroll}
        ref={tableRef}
      >
        <table {...getTableProps()} className="content-table">
          <thead>
            {customHeader && customHeader()}
            {headerGroups.map((headerGroup: HeaderGroup<object>, i: number) => (
              <React.Fragment key={i}>
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(
                    (column: ColumnInstance<object>, x: number) => {
                      return (
                        <React.Fragment key={x}>
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps(),
                              {
                                style: {
                                  minWidth: column.minWidth,
                                  width: column.width,
                                  maxWidth: column.maxWidth,
                                  zIndex: '999!important',
                                },
                              },
                            )}
                            className={clsx({ active: column.isSorted })}
                          >
                            <Flex
                              justifyContent={'flex-start'}
                              alignItems={'center'}
                              position={'relative'}
                            >
                              {column.render('Header')}
                              {!column.disableSortBy && (
                                <Flex
                                  alignItems={'center'}
                                  position={'absolute'}
                                  right={'0'}
                                  top={'50%'}
                                  transform={'translateY(-50%)'}
                                >
                                  <Box fontSize={smallHeader ? '12px' : '18px'}>
                                    {column.isSorted ? (
                                      column.isSortedDesc ? (
                                        <HiBarsArrowDown
                                          style={{ marginLeft: '12px' }}
                                        />
                                      ) : (
                                        <HiBarsArrowUp
                                          style={{ marginLeft: '12px' }}
                                        />
                                      )
                                    ) : (
                                      <HiArrowsUpDown
                                        style={{ marginLeft: '12px' }}
                                      />
                                    )}
                                  </Box>
                                </Flex>
                              )}
                            </Flex>
                          </th>
                        </React.Fragment>
                      )
                    },
                  )}
                </tr>
              </React.Fragment>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row: any, i: number) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()} key={i}>
                  {row.cells.map((cell: any, x: number) => {
                    return (
                      <td
                        key={x}
                        {...cell.getCellProps({
                          style: {
                            minWidth: cell.column.minWidth,
                            width: cell.column.width,
                            maxWidth: cell.column.maxWidth,
                          },
                        })}
                        className={cell?.column?.className}
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
          {/* <tfoot>
            {footerGroups.map((footerGroup: HeaderGroup) => (
              <tr {...footerGroup.getFooterGroupProps()}>
                {footerGroup.headers.map((column: any) => (
                  <td {...column.getFooterProps()}>
                    {column.render('Footer')}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot> */}
        </table>
      </div>
      <div className="footer-container">
        <div
          className={clsx('scroll-to-top', { active: showScrollTopButton })}
          onClick={scrollToTop}
        >
          <HiOutlineChevronUp />
        </div>
      </div>
    </Box>
  )
})
