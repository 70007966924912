import { CellProps } from 'react-table'

import { BasicDateColumn, BasicSumColumn, BasicTitle } from 'shared/ui'

export const TableStructure = () => {
  const size = 'lg'

  const structure = [
    {
      title: () => <BasicTitle size={size}>День</BasicTitle>,
      accessor: 'date',
      disableSortBy: true,
      minWidth: 80,
      width: 80,
      CellComponent: ({ row }: CellProps<any, string>) => {
        return (
          <BasicDateColumn size={size}>{row.original.date}</BasicDateColumn>
        )
      },
    },
    {
      title: () => <BasicTitle size={size}>Час</BasicTitle>,
      accessor: 'hour_number',
      disableSortBy: true,
      minWidth: 30,
      width: 30,
      CellComponent: ({ row }: CellProps<any, string>) => {
        return (
          <BasicSumColumn size={size} align={'center'}>
            {row.original.hour_number}
          </BasicSumColumn>
        )
      },
    },
  ]

  return structure
}
