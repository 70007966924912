import React from 'react'
import { Box, Button, Text, useDisclosure, Flex } from '@chakra-ui/react'

import { useGetAllEnergySystems } from 'entities/referenceBooks'
import { useDeletePeakHoursIntervalsReport } from '../models/services/deleteReportService'

import { BaseModal } from 'shared/ui'

// misc
import warningLogo from 'assets/svg/warning_logo.svg'

interface DeleteReportProps {
  year: string
  report_type: string
  ue_system_id?: string
  price_zone_id?: string
}

export const DeletePeakHoursIntervalsReport: React.FC<DeleteReportProps> = ({
  year,
  report_type,
  ue_system_id,
  price_zone_id,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { energySystemsData } = useGetAllEnergySystems({
    enabled: true,
  })

  const { mutate: deletePeakHoursIntervalsReport, isLoading } =
    useDeletePeakHoursIntervalsReport()

  const reportName = {
    day_zone_intervals: 'Интервалы зон суток',
    ue_system_operator_hours: 'Часы СО ЕЭС',
  }

  const handleCloseModal = () => onClose()

  const handleDelete = () => {
    deletePeakHoursIntervalsReport({
      report_type,
      year,
      successAction: () => handleCloseModal(),
      ...(price_zone_id && { price_zone_id }),
      ...(ue_system_id && { ue_system_id }),
    })
  }

  return (
    <div>
      <Button
        mr={'5px'}
        isDisabled={isLoading}
        isLoading={isLoading}
        colorScheme={'red'}
        size={'xs'}
        color="white"
        display={'flex'}
        alignItems={'center'}
        onClick={() => onOpen()}
      >
        Очистить
      </Button>
      <BaseModal
        header={`Очистить ${reportName[report_type]}`}
        id="delete user"
        isOpen={isOpen}
        onClose={handleCloseModal}
      >
        <Box>
          <Flex justifyContent={'center'}>
            <Box w={'50px'} mb={'15px'}>
              <img src={warningLogo} alt="Warning" />
            </Box>
          </Flex>
          <Text
            fontWeight={'800'}
            mb={'5px'}
            color={'red.500'}
            textAlign={'center'}
          >
            {reportName[report_type]} будут очищены
          </Text>
          <Text fontWeight={'600'} mb={'5px'} textAlign={'center'}>
            Вы действительно хотите очистить {reportName[report_type]} за период{' '}
            {year} год
            {/* для{' '} */}
            {/* {
              energySystemsData.find((item) => item.value === ue_system_id)
                ?.label
            } */}
            ?
          </Text>
        </Box>
        <Flex justifyContent={'flex-end'} mt="35px">
          <Button fontWeight={'500'} mr="15px" onClick={onClose}>
            Отмена
          </Button>
          <Button
            fontWeight={'500'}
            onClick={handleDelete}
            colorScheme={'red'}
            isDisabled={isLoading}
            isLoading={isLoading}
          >
            Очистить
          </Button>
        </Flex>
      </BaseModal>
    </div>
  )
}
