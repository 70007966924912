import React, { useCallback, useMemo, useState } from 'react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Text,
} from '@chakra-ui/react'

import { GetMonitoringTemplateResponse } from 'shared/models'
import { TextLabelTitle, TextTitle } from 'shared/ui'
import { EditMonitorigTemplateItem } from 'features/prices'

interface EditMonitoringTemplatesProps {
  openedTemplates: boolean
  setOpenedTemplates: React.Dispatch<React.SetStateAction<boolean>>
  templates: GetMonitoringTemplateResponse[]
}

export const EditMonitoringTemplates: React.FC<
  EditMonitoringTemplatesProps
> = ({
  openedTemplates,
  setOpenedTemplates,
  templates = [],
}): React.ReactElement => {
  const [expandedIds, setExpandedIds] = useState<string[]>([])

  const idToIndexMap = useMemo(() => {
    return templates?.reduce((acc, template, index) => {
      acc[template.monitoring_template_name] = index
      return acc
    }, {} as Record<string, number>)
  }, [templates])

  const expandedIndexes = useMemo(
    () =>
      expandedIds
        .map((id) => idToIndexMap[id])
        .filter((index) => index !== undefined),
    [expandedIds, idToIndexMap],
  )

  const handleToggle = useCallback((id: string) => {
    setExpandedIds((currentIds) =>
      currentIds.includes(id)
        ? currentIds.filter((currentId) => currentId !== id)
        : [...currentIds, id],
    )
  }, [])

  return (
    <Box h={'75vh'} position={'relative'} p={2} overflowY="scroll">
      <Flex flexDirection={'column'}>
        <Flex alignItems={'center'}>
          <TextTitle size="small">Список Шаблонов</TextTitle>
        </Flex>
        {templates && templates.length > 0 ? (
          <Box
            mt="15px"
            borderLeft="1px solid #dfe0eb"
            borderRight="1px solid #dfe0eb"
          >
            <Accordion
              allowMultiple
              width="100%"
              rounded="lg"
              display="block"
              index={expandedIndexes}
              onChange={(newIndexes) => {
                const indexes = Array.isArray(newIndexes)
                  ? newIndexes
                  : [newIndexes]
                setExpandedIds(
                  indexes.map(
                    (index) => templates[index].monitoring_template_name,
                  ),
                )
              }}
            >
              {templates.map((template, index) => (
                <AccordionItem key={template.monitoring_template_name}>
                  <AccordionButton
                    onClick={() =>
                      handleToggle(template.monitoring_template_name)
                    }
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={1}
                    bg="#ebeeff3c"
                  >
                    <Text fontSize="16px" fontWeight="600" color={'#4E5A70'}>
                      {template.monitoring_template_name}
                    </Text>
                    <ChevronDownIcon />
                  </AccordionButton>

                  <AccordionPanel pb={4}>
                    <EditMonitorigTemplateItem {...template} />
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </Box>
        ) : (
          <Box p={'15px'}>
            <TextLabelTitle size="medium">Отсутствуют</TextLabelTitle>
          </Box>
        )}
      </Flex>
    </Box>
  )
}
