import { AxiosError, AxiosResponse } from 'axios'
import { UseMutateFunction, useMutation } from 'react-query'
import { saveAs } from 'file-saver'

import { ExtendedAxiosError, UploadExcelResponse } from 'shared/models'
import { PricesServiceService } from 'shared/services'
import { toasts } from 'shared/utils'

interface DownloadReport {
  successAction: () => void
  failureAction: () => void
  year: string
  report_type: string
  reportName: string
  time_zone_by_msk: string
  ue_system_id?: string
  price_zone_id?: string
}

export function useDownloadPeakHoursIntervalsReport(): {
  mutate: UseMutateFunction<
    AxiosResponse<Blob>,
    AxiosError,
    DownloadReport,
    unknown
  >
  isLoading: boolean
} {
  const { mutate, isLoading } = useMutation(
    ({
      year,
      ue_system_id,
      price_zone_id,
      report_type,
      time_zone_by_msk,
    }: DownloadReport) =>
      PricesServiceService.downloadPeakHoursIntervalsReport({
        year,
        ue_system_id,
        price_zone_id,
        report_type,
        time_zone_by_msk,
      }),
    {
      onSuccess: (response: AxiosResponse<Blob>, variables: DownloadReport) => {
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })

        saveAs(blob, `${variables.year} Отчёт ${variables.reportName}.xlsx`)

        if (variables.successAction) {
          variables.successAction()
          toasts.success({
            title: 'Успех',
            description: `Файл успешно загружен`,
          })
        }
      },
      onError: (error: ExtendedAxiosError, variables: DownloadReport) => {
        const errorMessage =
          error.response?.data.detail || 'Загрузка файла не удалась'
        toasts.error({ title: 'Произошла ошибка', description: errorMessage })

        variables.failureAction()
      },
    },
  )

  return { mutate, isLoading }
}
