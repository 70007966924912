import { useQuery } from 'react-query'

import {
  ExtendedAxiosError,
  GetViewFSKTariffsData,
  GetDataViewFSKTariffsResponse,
  UploadFileInfo,
} from 'shared/models'

import { queryKeys } from 'shared/constants'
import { PricesServiceService } from 'shared/services'
import { toasts } from 'shared/utils'

interface UseGetDataViewFSKTariffsResult {
  data: GetViewFSKTariffsData[] | undefined
  upload_info: UploadFileInfo | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
  refetch: () => void
}

interface UseGetDataFSKTariffsHours {
  enabled: boolean
  date_period: string
  successAction?: () => void
  errorAction?: () => void
}

export function useGetDataFSKTariffsHours({
  enabled,

  date_period,
  successAction,
  errorAction,
}: UseGetDataFSKTariffsHours): UseGetDataViewFSKTariffsResult {
  const { data, isError, isFetching, isLoading, refetch } =
    useQuery<GetDataViewFSKTariffsResponse>({
      queryKey: [queryKeys.prices.dataViewFSKTariffs, date_period],
      queryFn: () =>
        PricesServiceService.getDataViewFSKTariffs({
          date_period,
        }).then((response) => response.data),
      enabled,
      onError: (error: ExtendedAxiosError) => {
        if (errorAction) errorAction()
        toasts.error({
          title: 'Произошла ошибка',
          description: error.response.data.detail || 'Данные не были загружены',
        })
      },
      onSuccess: () => {
        if (successAction) successAction()
      },
    })

  return {
    data: data?.data,
    upload_info: data?.upload_info,
    isError,
    isFetching,
    isLoading,
    refetch,
  }
}
