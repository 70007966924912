import React, { useEffect, useMemo, useState } from 'react'

import {
  Box,
  Button,
  Flex,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react'

import { Access } from 'app/providers'
import { useGetAllSubjects } from 'entities/referenceBooks'
import { AddNewSubject, EditSubjects } from 'features/referenceBooks'
import { SubjectsResponse } from 'shared/models'
import { FilterElements, SortingElements, SortStage, Tooltip } from 'shared/ui'
import { accessDict, modulesDict } from 'shared/dictionary'

//  misc
import { HiMiniPlusCircle, HiMiniXMark } from 'react-icons/hi2'

export const SubjectsList: React.FC = () => {
  const { isOpen, onToggle } = useDisclosure()
  const [subjects, setSubjects] = useState<SubjectsResponse[]>([])
  const [filter, setFilter] = useState<string>('')
  const [sortOrderByName, setSortOrderByName] = useState<SortStage>(
    SortStage.none,
  )
  const [sortByCode, setSortOrderByCode] = useState<SortStage>(SortStage.none)

  const { data, isLoading } = useGetAllSubjects({
    enabled: true,
  })

  useEffect(() => {
    if (data) setSubjects(data)
  }, [data])

  const handleSortByName = () => {
    setSortOrderByName((currentSortOrder) =>
      currentSortOrder === SortStage.none
        ? SortStage.asc
        : currentSortOrder === SortStage.asc
        ? SortStage.desc
        : SortStage.none,
    )
    setSortOrderByCode(SortStage.none)
  }

  const handleSortByCode = () => {
    setSortOrderByCode((currentSortOrder) =>
      currentSortOrder === SortStage.none
        ? SortStage.asc
        : currentSortOrder === SortStage.asc
        ? SortStage.desc
        : SortStage.none,
    )
    setSortOrderByName(SortStage.none)
  }

  const sortedAndFilteredSubjects = useMemo(() => {
    const sortedSubjects = subjects.sort((a, b) => {
      if (sortOrderByName !== SortStage.none) {
        const regionA = a.region_name?.toLowerCase() ?? ''
        const regionB = b.region_name?.toLowerCase() ?? ''
        if (sortOrderByName === SortStage.asc)
          return regionA.localeCompare(regionB)
        if (sortOrderByName === SortStage.desc)
          return regionB.localeCompare(regionA)
      } else if (sortByCode !== SortStage.none) {
        const codeA = a.region_code ?? 0
        const codeB = b.region_code ?? 0
        if (sortByCode === SortStage.asc) return codeA - codeB
        if (sortByCode === SortStage.desc) return codeB - codeA
      }
      return 0
    })

    return sortedSubjects.filter((subject) => {
      const regionName = subject.region_name?.toLowerCase() ?? ''
      const regionCode = subject.region_code?.toString() ?? ''
      const filterLower = filter?.toLowerCase() ?? ''
      return (
        regionName.includes(filterLower) || regionCode.includes(filterLower)
      )
    })
  }, [filter, subjects, sortOrderByName, sortByCode])

  const addSubjectProps = {
    isOpen,
    onToggle,
  }

  const subjectFilterProps = {
    filter,
    setFilter,
    delay: 0,
  }

  if (isLoading)
    return (
      <>
        <Flex align="center" justify="center" height="100vh">
          <Spinner
            thickness="2px"
            speed="0.35s"
            emptyColor="gray.200"
            color="blue.500"
            size="md"
          />
        </Flex>
      </>
    )

  return (
    <Box maxH="90vh" overflowY="hidden">
      <Flex py="8px" alignItems={'center'}>
        <Box ml={'8px'}>
          <Tooltip
            delayShow={500}
            id={`subject-sort-by-id`}
            content="Сортировка по коду Субъекта"
            place="top"
          >
            <SortingElements
              setSortOrder={handleSortByCode}
              sortOrder={sortByCode}
              title="По ID"
            />
          </Tooltip>
        </Box>
        <Box ml={'8px'}>
          <Tooltip
            delayShow={500}
            id={`subject-sort-by-name`}
            content="Сортировка по имени Субъекта"
            place="top"
          >
            <SortingElements
              setSortOrder={handleSortByName}
              sortOrder={sortOrderByName}
              title="По имени"
            />
          </Tooltip>
        </Box>
        <Box ml="5px">
          <FilterElements {...subjectFilterProps} />
        </Box>

        <Access
          permissions={[accessDict.update_read]}
          module={modulesDict.reference_books}
        >
          <Box ml={'8px'}>
            {isOpen ? (
              <Button
                position={'relative'}
                size={'xs'}
                display={'flex'}
                alignItems={'center'}
                onClick={() => onToggle()}
              >
                <Box fontSize={'18px'}>
                  <HiMiniXMark />
                </Box>
                <Text position={'relative'}>Отмена</Text>
              </Button>
            ) : (
              <Button
                position={'relative'}
                size={'xs'}
                bg="blue.700"
                color="white"
                display={'flex'}
                alignItems={'center'}
                onClick={() => onToggle()}
                _hover={{
                  bg: 'blue.600',
                }}
              >
                <Box fontSize={'18px'} mr={'5px'}>
                  <HiMiniPlusCircle />
                </Box>
                <Text position={'relative'}>Добавить Субъект РФ</Text>
              </Button>
            )}
          </Box>
        </Access>
      </Flex>
      <Access
        permissions={[accessDict.update_read]}
        module={modulesDict.reference_books}
      >
        <Box
          position="relative"
          zIndex="1"
          boxShadow="0px 4px  10px rgba(0, 0, 0, 0.12)"
          borderBottom={'1px solid #eeeeee'}
          borderTop={'1px solid #319795'}
        >
          <AddNewSubject {...addSubjectProps} />
        </Box>
      </Access>

      <Box h={'100vh'} overflowY="scroll">
        <Flex
          borderBottom={'1px solid #eeeeee'}
          alignItems={'center'}
          p={'3px 0'}
        >
          <Text ml={'25px'} fontSize={'sm'}>
            ID
          </Text>
          <Text ml={'22px'} fontSize={'sm'}>
            Наименование
          </Text>
        </Flex>
        <EditSubjects subjects={sortedAndFilteredSubjects} />
      </Box>
    </Box>
  )
}
