import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
  Box,
  Button,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'

import {
  DataViewSalesMarkups,
  DataViewTransService,
  DataViewTransServiceFSK,
} from 'widgets'

import {
  TransFSKTableType,
  TransType,
} from 'widgets/prices/transServise/types/types'

import { useUrlParams } from 'shared/utils'

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

export const TariffsPage: React.FC = (): React.ReactElement => {
  const query = useQuery()
  const queryPeriod = query.get('period')
  const {
    query_pageType,
    query_subject_id,
    query_date,
    query_gs_activity_areas_id,
  } = useUrlParams()

  const [tabIndex, setTabIndex] = useState<number>(0)
  const [transType, setTransType] = useState<TransType>(TransType.byBoiler)
  const [fskType, setFSKType] = useState<TransFSKTableType>(
    TransFSKTableType.networks,
  )

  useEffect(() => {
    if (query_pageType === 'Котловые' || query_pageType === 'ФСК')
      setTabIndex(1)
    if (query_pageType === 'Котловые') setTransType(TransType.byBoiler)
    if (query_pageType === 'ФСК') setTransType(TransType.byFSK)
    if (query_pageType === 'Сбытовые надбавки') setTabIndex(0)
  }, [query_pageType])

  const handleTabsChange = (index) => setTabIndex(index)

  const dataViewTransServiceProps = {
    query_subject_id,
    query_date,
  }

  const dataViewTransServiceFSKProps = {
    fskType,
    query_date,
  }

  const dataViewSalesMarkupsProps = {
    query_date,
    query_gs_activity_areas_id,
  }

  return (
    <Tabs index={tabIndex} onChange={handleTabsChange}>
      <TabList p={'10px'} pb={0}>
        <Tab>
          <Text fontSize={'18px'}>Сбытовые надбавки ГП</Text>
        </Tab>
        <Tab>
          <Text fontSize={'18px'}>Услуги по передачи</Text>
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel padding={0}>
          <DataViewSalesMarkups {...dataViewSalesMarkupsProps} />
        </TabPanel>
        <TabPanel padding={0}>
          <Box p="1">
            <Box ml="10px">
              <Box>
                <Flex alignItems={'center'} mt={'10px'}>
                  <Button
                    w={'135px'}
                    size="xs"
                    borderRadius={0}
                    borderLeftRadius={'5px'}
                    border={'1px solid #ececec'}
                    borderRight={'none'}
                    _hover={{ bg: '#3285d2', color: 'white' }}
                    bg={
                      transType === TransType.byBoiler ? '#2d70af' : '#f8f8f8'
                    }
                    color={transType === TransType.byBoiler ? 'white' : 'black'}
                    onClick={() => setTransType(TransType.byBoiler)}
                  >
                    Котловые
                  </Button>
                  <Button
                    w={'135px'}
                    size="xs"
                    borderRadius={0}
                    borderRightRadius={'5px'}
                    border={'1px solid #ececec'}
                    _hover={{ bg: '#3285d2', color: 'white' }}
                    bg={transType === TransType.byFSK ? '#2d70af' : '#f8f8f8'}
                    color={transType === TransType.byFSK ? 'white' : 'black'}
                    onClick={() => setTransType(TransType.byFSK)}
                  >
                    ФСК
                  </Button>
                  {transType === TransType.byFSK && (
                    <>
                      <Button
                        ml={'10px'}
                        w={'135px'}
                        size="xs"
                        borderRadius={0}
                        borderLeftRadius={'5px'}
                        border={'1px solid #ececec'}
                        borderRight={'none'}
                        _hover={{ bg: '#3285d2', color: 'white' }}
                        bg={
                          fskType === TransFSKTableType.networks
                            ? '#2d70af'
                            : '#f8f8f8'
                        }
                        color={
                          fskType === TransFSKTableType.networks
                            ? 'white'
                            : 'black'
                        }
                        onClick={() => setFSKType(TransFSKTableType.networks)}
                      >
                        Содержание сетей
                      </Button>
                      <Button
                        w={'135px'}
                        size="xs"
                        borderRadius={0}
                        borderRightRadius={'5px'}
                        border={'1px solid #ececec'}
                        _hover={{ bg: '#3285d2', color: 'white' }}
                        bg={
                          fskType === TransFSKTableType.losses
                            ? '#2d70af'
                            : '#f8f8f8'
                        }
                        color={
                          fskType === TransFSKTableType.losses
                            ? 'white'
                            : 'black'
                        }
                        onClick={() => setFSKType(TransFSKTableType.losses)}
                      >
                        Норматив потерь
                      </Button>
                    </>
                  )}
                </Flex>
              </Box>
            </Box>
          </Box>

          <Tabs index={transType === TransType.byBoiler ? 0 : 1} isManual>
            <TabPanels>
              <TabPanel padding={0}>
                <DataViewTransService {...dataViewTransServiceProps} />
              </TabPanel>
              <TabPanel padding={0}>
                <DataViewTransServiceFSK {...dataViewTransServiceFSKProps} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}
