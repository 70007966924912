import { Box, Button, Flex, useDisclosure, Text } from '@chakra-ui/react'

import moment from 'moment'
import { BaseModal } from 'shared/ui'
import { useDeleteActivityArea } from '../models/services/deleteActivityAreaService'

// misc
import warningLogo from 'assets/svg/warning_logo.svg'
import { HiOutlineArchiveBoxXMark } from 'react-icons/hi2'

interface DeleteActivityArea {
  gs_activity_areas_id: string
  area_name: string
  setExpandedIds: React.Dispatch<React.SetStateAction<string[]>>
}

export const DeleteActivityArea: React.FC<DeleteActivityArea> = (props) => {
  const { gs_activity_areas_id, area_name, setExpandedIds } = props
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { mutate: deleteActivityArea, isLoading } = useDeleteActivityArea()

  const handleCloseModal = () => onClose()

  const handleDeleteActivityArea = () => {
    deleteActivityArea({
      gs_activity_areas_id,
      successAction: () => {
        onClose(),
          setExpandedIds((currentIds) =>
            currentIds.filter((id) => id !== gs_activity_areas_id),
          )
      },
    })
  }

  return (
    <div>
      <Button
        isDisabled={isLoading}
        isLoading={isLoading}
        colorScheme={'red'}
        size={'xs'}
        color="white"
        display={'flex'}
        alignItems={'center'}
        onClick={() => onOpen()}
      >
        Удалить зону дейтиельности
        <Box fontSize={'18px'} ml={'7px'}>
          <HiOutlineArchiveBoxXMark />
        </Box>
      </Button>
      <BaseModal
        header="Удаление зоны деятельности"
        id="delete user"
        isOpen={isOpen}
        onClose={handleCloseModal}
      >
        <Box>
          <Flex justifyContent={'center'}>
            <Box w={'50px'} mb={'15px'}>
              <img src={warningLogo} alt="Warning" />
            </Box>
          </Flex>
          <Text
            fontWeight={'800'}
            mb={'5px'}
            color={'red.500'}
            textAlign={'center'}
          >
            Зона деятельности будет удалена ГП
          </Text>
          <Text fontWeight={'600'} mb={'5px'} textAlign={'center'}>
            Вы действительно хотите удалить следующую зону дейтиельности ГП{' '}
            <b>{area_name}</b>?
          </Text>
        </Box>
        <Flex justifyContent={'flex-end'} mt="35px">
          <Button fontWeight={'500'} mr="15px" onClick={onClose}>
            Отмена
          </Button>
          <Button
            fontWeight={'500'}
            onClick={handleDeleteActivityArea}
            colorScheme={'red'}
            isDisabled={isLoading}
            isLoading={isLoading}
          >
            Удалить
          </Button>
        </Flex>
      </BaseModal>
    </div>
  )
}
