import React, { useMemo, useState, memo, useEffect } from 'react'

import { Box, Flex, Text, Button } from '@chakra-ui/react'

import {
  DeletePeakHoursIntervalsReport,
  DownloadPeakHoursIntervalsReport,
  EditUeSystemOperatorHours,
} from 'features/prices'
import { useGetUeSystemOperatorHoursService } from 'entities/prices'
import { useGetAllPriceZones } from 'entities/referenceBooks'
import { DatePickerField, FormInputBlock, SelectInputForm } from 'shared/ui'
import { normalizeValues } from 'shared/utils'

import { useHoursSOEASForm } from './HoursSOEASForm'

// misc
import { HiOutlineTableCells } from 'react-icons/hi2'

interface HoursSOEASProps {
  query_date: string
  query_price_zone_id: string
}

export const HoursSOEAS: React.FC<HoursSOEASProps> = memo(
  ({ query_date, query_price_zone_id }): JSX.Element => {
    const [fetchEnabled, setFetchEnabled] = useState<{
      enabled: boolean
    }>({
      enabled: false,
    })

    // console.log('query_price_zone_id ===>', query_price_zone_id)

    const {
      register,
      handleSubmit,
      errors,
      reset,
      isDirty,
      watchedFields,
      control,
      setValue,
      setError,
      watch,
      isValid,
    } = useHoursSOEASForm()

    const year = normalizeValues(watchedFields.date_period, 'date_only_year')

    const { data, isLoading, isFetching, refetch } =
      useGetUeSystemOperatorHoursService({
        enabled: fetchEnabled.enabled,
        price_zone_id: watchedFields.price_zone_id,
        date_period: year,
      })

    const { priceZonesData } = useGetAllPriceZones({
      enabled: true,
    })

    useEffect(() => {
      if (query_date || query_price_zone_id) {
        reset({
          date_period: query_date
            ? normalizeValues(query_date, 'date_only_year')
            : null,
          price_zone_id: query_price_zone_id ? query_price_zone_id : null,
        })
      }
    }, [query_date, query_price_zone_id])

    const commonInputProps = useMemo(
      () => ({
        control,
        register,
        errors,
        watchedFields,
        size: 'sm' as 'sm',
        smallErrorTextInside: true,
        isRequired: true,
      }),
      [register, errors, watchedFields, control],
    )

    const editUeSystemOperatorHoursProps = {
      data,
      year,
      price_zone_id: watchedFields.price_zone_id,
    }

    const downloadUeSystemOperatorHoursReportProps = {
      year,
      price_zone_id: watchedFields.price_zone_id,
      isDisabled: watchedFields.price_zone_id && !watchedFields.date_period,
      reportName: 'часы пиковой нагрузки СО ЕЭС',
    }

    const deleteDayZoneIntervalsReportProps = {
      report_type: 'ue_system_operator_hours',
      year,
      price_zone_id: watchedFields.price_zone_id,
    }

    const handleFetchData = () => refetch()

    return (
      <Box p="2">
        <form>
          <Box ml="10px">
            <Flex alignItems={'center'}>
              <Box mr="25px" w={'150px'}>
                <FormInputBlock
                  titleWidth={'60px'}
                  allowEdit={true}
                  title={'Период'}
                  edit={true}
                >
                  <DatePickerField
                    setInitialDate
                    isReadOnly
                    showOnlyYearPicker
                    showYearPicker
                    format="yyyy"
                    showMonthYearPicker
                    name={'date_period'}
                    placeholder={'Период'}
                    type={'text'}
                    theme="yearPicker"
                    {...commonInputProps}
                  />
                </FormInputBlock>
              </Box>
              <Box w={'480px'}>
                <FormInputBlock
                  titleWidth={'100px'}
                  allowEdit={true}
                  edit
                  title={'Ценовая зона'}
                >
                  <SelectInputForm
                    placeholder="Выберите ценовую зону"
                    getOptionValue={(option) => (option ? option.value : '')}
                    name="price_zone_id"
                    data={priceZonesData || []}
                    isClearable={true}
                    {...commonInputProps}
                  />
                </FormInputBlock>
              </Box>
            </Flex>
            <Box mt="20px">
              <Flex>
                <Box ml="10px">
                  <Button
                    w={'135px'}
                    position={'relative'}
                    size={'xs'}
                    color="white"
                    display={'flex'}
                    alignItems={'center'}
                    onClick={handleFetchData}
                    colorScheme="teal"
                    isDisabled={!isValid}
                    isLoading={isLoading || isFetching}
                  >
                    <Box fontSize={'18px'} mr={'5px'}>
                      <HiOutlineTableCells />
                    </Box>
                    <Text position={'relative'}>Показать</Text>
                  </Button>
                </Box>
              </Flex>
            </Box>
          </Box>

          {data && (
            <Box
              mt="15px"
              p="2"
              border={'1px solid #dfe0eb'}
              borderRadius={'5px'}
            >
              <EditUeSystemOperatorHours
                downloadReportSlot={
                  <DownloadPeakHoursIntervalsReport
                    {...downloadUeSystemOperatorHoursReportProps}
                  />
                }
                deleteReportSlot={
                  <DeletePeakHoursIntervalsReport
                    {...deleteDayZoneIntervalsReportProps}
                  />
                }
                {...editUeSystemOperatorHoursProps}
              />
            </Box>
          )}
        </form>
      </Box>
    )
  },
)
