import { useQuery } from 'react-query'

import { GetMonitoringTemplateResponse } from 'shared/models'
import { queryKeys } from 'shared/constants'
import { MonitoringPricesService } from 'shared/services'

interface UseGetAllMonitoringTemplatesServiceResult {
  data: GetMonitoringTemplateResponse[] | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
}

interface UseGetAllMonitoringTemplates {
  enabled: boolean
}

export function useGetAllMonitoringTemplates({
  enabled,
}: UseGetAllMonitoringTemplates): UseGetAllMonitoringTemplatesServiceResult {
  const { data, isError, isFetching, isLoading } = useQuery<
    GetMonitoringTemplateResponse[]
  >({
    queryKey: [queryKeys.prices.allMonitoringTemplates],
    queryFn: () =>
      MonitoringPricesService.getMonitoringTemplates().then(
        (response) => response.data,
      ),
    enabled,
    retry: false,
  })

  return { data, isError, isFetching, isLoading }
}
