import { useSearchParams } from 'react-router-dom'
import { Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'

import {
  DataViewATCHours,
  DataViewFSKTariffs,
  DataViewSVNCEM,
} from 'widgets/prices'

const ReportType = {
  SVNC: 'svnc',
  PEAK: 'peak',
  TARIFF_FGC: 'tariff_fgc',
}

export const PriceDataPage = () => {
  const [searchParams] = useSearchParams()
  const reportType = searchParams.get('report_type')

  const defaultIndex =
    reportType === ReportType.SVNC
      ? 0
      : reportType === ReportType.PEAK
      ? 1
      : reportType === ReportType.TARIFF_FGC
      ? 2
      : 1

  return (
    <Tabs defaultIndex={defaultIndex}>
      <TabList p={'10px'} pb={0}>
        <Tab>
          <Text fontSize={'18px'}>СВНЦЭМ</Text>
        </Tab>
        <Tab>
          <Text fontSize={'18px'}>Часы АТС</Text>
        </Tab>
        <Tab>
          <Text fontSize={'18px'}>Тарифы ФСК</Text>
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel padding={0}>
          <DataViewSVNCEM />
        </TabPanel>
        <TabPanel padding={0}>
          <DataViewATCHours />
        </TabPanel>
        <TabPanel padding={0}>
          <DataViewFSKTariffs />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}
