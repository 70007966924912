import React, { useMemo } from 'react'
import { map } from 'lodash'

import {
  Box,
  Button,
  Collapse,
  Flex,
  Text,
  useDisclosure,
} from '@chakra-ui/react'

import {
  FormInputBlock,
  FormInputControl,
  SelectInputForm,
  SubmitCancelButtons,
  TextTitle,
} from 'shared/ui'

import { useGetAllActivityAreas } from 'entities/referenceBooks'

import {
  CreateMonitoringTemplateFormData,
  useCreateMonitoringTemplateForm,
} from './CreateMontoringTemplateForm'
import { useCreateMonitoringemplate } from '../models/services/createMonitoringTemplateServie'

// misc
import { HiMiniPlusCircle, HiMiniXMark } from 'react-icons/hi2'

interface CreateMonitoringTemplateProps {
  setOpenedTemplates: React.Dispatch<React.SetStateAction<boolean>>
}

export const CreateMonitoringTemplate: React.FC<
  CreateMonitoringTemplateProps
> = ({ setOpenedTemplates }) => {
  const { isOpen, onToggle } = useDisclosure()

  const { activityAreasData = [] } = useGetAllActivityAreas({
    enabled: true,
  })
  const { mutate: createTemplate, isLoading } = useCreateMonitoringemplate()

  const {
    register,
    handleSubmit,
    errors,
    reset,
    control,
    watchedFields,
    isDirty,
    itemsFields,
    appendItem,
    removeItem,
    isValid,
    items,
  } = useCreateMonitoringTemplateForm()

  const onSubmit = ({
    template_name,
    items,
  }: CreateMonitoringTemplateFormData) => {
    const formattedItems = map(items, 'activity_zone')

    createTemplate({
      successAction: () => {
        onToggle()
        reset()
      },
      monitoring_template_name: template_name,
      gs_activity_areas_ids: formattedItems,
    })
  }

  const commonInputProps = useMemo(
    () => ({
      control,
      register,
      errors,
      watchedFields,
      size: 'sm' as 'sm',
      smallErrorTextInside: true,
      isRequired: true,
    }),
    [register, errors, watchedFields, control],
  )

  const disableAddButton = items.some((item) => item.activity_zone === '')

  const handleResetForm = () => reset()

  const getAvailableActivityAreas = (index) => {
    const selectedActivityZones = items.map((item) => item.activity_zone)
    return activityAreasData.filter(
      (area) =>
        !selectedActivityZones.includes(area.value) ||
        area.value === items[index].activity_zone,
    )
  }

  return (
    <Box>
      <Flex alignItems={'center'}>
        <Box ml={'8px'} py={2}>
          {isOpen ? (
            <Button
              position={'relative'}
              size={'xs'}
              display={'flex'}
              alignItems={'center'}
              onClick={() => onToggle()}
            >
              <Box fontSize={'18px'}>
                <HiMiniXMark />
              </Box>
              <Text position={'relative'}>Отмена</Text>
            </Button>
          ) : (
            <Button
              position={'relative'}
              size={'xs'}
              bg="blue.700"
              color="white"
              display={'flex'}
              alignItems={'center'}
              onClick={() => onToggle()}
              _hover={{
                bg: 'blue.600',
              }}
            >
              <Box fontSize={'18px'} mr={'5px'}>
                <HiMiniPlusCircle />
              </Box>
              <Text position={'relative'}>Добавить шаблон</Text>
            </Button>
          )}
        </Box>
      </Flex>
      <Box
        position="relative"
        zIndex="1"
        boxShadow="0px 4px  10px rgba(0, 0, 0, 0.12)"
        borderBottom={'1px solid #eeeeee'}
        borderTop={'1px solid #319795'}
      >
        <Collapse in={isOpen} animateOpacity style={{ overflow: 'inherit' }}>
          <Flex
            borderBottom={'1px solid #dfe0eb'}
            flexDir={'column'}
            borderRadius={'5px'}
          >
            <Box p={'10px'}>
              <TextTitle size={'small'}>Добавление нового шаблона</TextTitle>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box w={'600px'} p={2} m={2} border={'1px solid #dfe0eb'}>
                <FormInputBlock
                  titleWidth={'200px'}
                  allowEdit={true}
                  title={'Название шаблона:'}
                  edit={true}
                  value={''}
                >
                  <FormInputControl
                    name="template_name"
                    placeholder="Наименование"
                    type="text"
                    {...commonInputProps}
                  />
                </FormInputBlock>
                <Box mt={'10px'} />

                <Box>
                  {itemsFields.map((field, index) => {
                    const availableActivityAreas =
                      getAvailableActivityAreas(index)

                    return (
                      <Box key={field.id} mt={'10px'}>
                        <FormInputBlock
                          titleWidth={'200px'}
                          title={'Зона деятельности'}
                          allowEdit={true}
                          edit={true}
                        >
                          <SelectInputForm
                            placeholder="Зона деятельности"
                            data={availableActivityAreas}
                            name={`items.${index}.activity_zone`}
                            isClearable={true}
                            getOptionValue={(option) =>
                              option ? option.value : ''
                            }
                            {...commonInputProps}
                          />
                        </FormInputBlock>
                      </Box>
                    )
                  })}
                </Box>
                <Box mt="15px">
                  <Button
                    w={'fit-content'}
                    mr="10px"
                    position={'relative'}
                    size={'xs'}
                    bg="blue.700"
                    color="white"
                    display={'flex'}
                    alignItems={'center'}
                    onClick={() => appendItem({ activity_zone: '' })}
                    isDisabled={disableAddButton || isLoading}
                    _hover={{
                      bg: 'blue.600',
                    }}
                  >
                    <Box fontSize={'18px'} mr={'5px'}>
                      <HiMiniPlusCircle />
                    </Box>
                    <Text position={'relative'}>
                      Добавить зону деятельности
                    </Text>
                  </Button>
                </Box>
                {isDirty && (
                  <Box mt={'15px'}>
                    <SubmitCancelButtons
                      isDirty={isDirty}
                      isUpdating={isLoading}
                      handleCancel={handleResetForm}
                      isDisabled={!isValid}
                    />
                  </Box>
                )}
              </Box>
            </form>
          </Flex>
        </Collapse>
      </Box>
    </Box>
  )
}
