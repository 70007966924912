import React, { useCallback, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Text,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
} from '@chakra-ui/react'

import { PageScope } from 'app/providers'
import { useGetUserInfo } from 'entities/User'
import { COMPANIES_ROUTE, SETTINGS, USER_PROFILE_ROUTE } from 'shared/constants'
import { NavBar, ConfirmationModal } from 'shared/ui'

//misc
import { HiOutlineArrowRightOnRectangle } from 'react-icons/hi2'

interface MenuContentProps {
  onLogout: () => void
}

const MenuContent: React.FC<MenuContentProps> = ({ onLogout }) => {
  const navigate = useNavigate()

  return (
    <MenuList zIndex={11}>
      <MenuItem
        onClick={() => {
          navigate(USER_PROFILE_ROUTE)
        }}
      >
        Профиль
      </MenuItem>
      <MenuItem onClick={() => navigate(COMPANIES_ROUTE)}>Настройки</MenuItem>
      <MenuDivider />
      <MenuItem onClick={onLogout}>
        <HiOutlineArrowRightOnRectangle size={'22px'} />
        <Text ml="8px">Выйти</Text>
      </MenuItem>
    </MenuList>
  )
}

export const NavBarMenu: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { userInfo } = useGetUserInfo({
    enabled: false,
  })

  const { handleLogout } = useContext(PageScope)

  const handleLogOut = useCallback(() => {
    onOpen()
  }, [onOpen])

  const logOut = useCallback(() => {
    handleLogout()
  }, [handleLogout])

  const menuContent = useMemo(
    () => <MenuContent onLogout={handleLogOut} />,
    [handleLogOut],
  )

  return (
    <>
      <NavBar
        username={`${userInfo?.last_name || ''} ${userInfo?.first_name || ''}`}
        menuContent={menuContent}
      />
      <ConfirmationModal
        onSuccessAction={logOut}
        onClose={onClose}
        isOpen={isOpen}
        id="logout-modal"
        title="Вы уверены, что хотите выйти?"
      />
    </>
  )
}
