import React, { memo } from 'react'
import { Box } from '@chakra-ui/react'

import { GetViewFSKTariffsData } from 'shared/models'
import { TableEntity } from './TableEntity'

interface FSKTariffsTableProps {
  tableData: GetViewFSKTariffsData[]
}

export const FSKTariffsTable: React.FC<FSKTariffsTableProps> = memo(
  ({ tableData }) => {
    return (
      <Box margin={'-17px'} mt={'0px'}>
        <TableEntity tableData={tableData} />
      </Box>
    )
  },
)
