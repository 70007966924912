/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

import { GetGPDataData } from 'shared/models'
import { BasicTable } from 'shared/ui'

import { TableStructure } from './TableStructure'

interface ITableEntityProps {
  tableData: GetGPDataData[]
  editMode: boolean
}

export const TableEntity: React.FC<ITableEntityProps> = (
  props,
): JSX.Element => {
  const { tableData = [], editMode } = props

  const columns = TableStructure(editMode)

  const tableProps = {
    tableStyles: {
      tableMaxHeight: '65vh',
      tableWidth: '100%',
    },
    data: tableData,
    columns,
    emptyText: 'Данные отсутствуют',
    loading: false,
    showTableMenu: false,
    smallShadow: true,
    lightHeader: true,
    smallHeader: false,
    resizable: false,
  }

  return (
    <>
      <BasicTable {...tableProps} />
    </>
  )
}
