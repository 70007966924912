import { Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'
import { Monitoring } from 'widgets/prices'

export const PriceMonitoringPage = () => {
  return (
    <Tabs>
      <TabList p={'10px'} pb={0}>
        <Tab>
          <Text fontSize={'18px'}>Мониторинг</Text>
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel padding={0}>
          <Monitoring />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}
