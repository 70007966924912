import React, { memo } from 'react'
import { Box } from '@chakra-ui/react'

import { TableEntity } from './TableEntity'
import { UeSystemOperatorHoursTableRow } from '../../types/types'

interface UeSystemOperatorHoursTableProps {
  tableData: UeSystemOperatorHoursTableRow[]
  editMode: boolean
}

export const UeSystemOperatorHoursTable: React.FC<UeSystemOperatorHoursTableProps> =
  memo(({ tableData, editMode }): JSX.Element => {
    return (
      <Box margin={'-17px'} mt={'0px'}>
        <TableEntity tableData={tableData} editMode={editMode} />
      </Box>
    )
  })
