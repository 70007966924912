import React from 'react'
import { Box, Flex } from '@chakra-ui/react'

import { GetDataViewATCHoursData, UploadFileInfo } from 'shared/models'
import { EmptyDataMessage, UploadFileInfoView } from 'shared/ui'
import { ATCHoursTable } from './ATCHoursTable/ATCHoursable'

interface DataViewATCHoursTableProps {
  data: GetDataViewATCHoursData[]
  upload_info: UploadFileInfo
  downloadReportSlot?: JSX.Element
}

export const DataViewATCHoursTable: React.FC<DataViewATCHoursTableProps> = ({
  data,
  upload_info,
  downloadReportSlot,
}) => {
  return (
    <Box maxW={'500px'}>
      <Flex mb="17px">
        {data && data?.length !== 0 && (
          <Flex ml="auto" mr={'-10px'} alignItems={'center'}>
            {upload_info && <UploadFileInfoView {...upload_info} />}
            {downloadReportSlot ? downloadReportSlot : null}
          </Flex>
        )}
      </Flex>

      {data && (
        <Box p={'17px'} pt={'0px'}>
          {Array.isArray(data) && data.length === 0 ? (
            <EmptyDataMessage />
          ) : (
            <>
              <ATCHoursTable tableData={data} />
            </>
          )}
        </Box>
      )}
    </Box>
  )
}
