import React from 'react'

import { Access } from 'app/providers'
import { accessDict, modulesDict } from 'shared/dictionary'
import { CustomLink } from 'shared/ui'
import {
  AGREEMENT,
  CALENDAR,
  COMPANIES_ROUTE,
  COUNTER_PARTIES,
  REFERENCE_BOOKS_ROUTE,
  PRICE_CATEGORIES,
  REGIONS,
  ROLES,
  USERS_ROUTE,
  PRICES_ROUTE,
  MONITORING,
  PRICE_DATA,
  IMPORT_DATA,
  TARIFFS,
  GP_DATA,
  HOURS_INTERVALS,
} from 'shared/constants'

// misc

import {
  HiOutlineBuildingOffice2,
  HiOutlineUserGroup,
  HiOutlineCalendarDays,
  HiOutlineChartBar,
  HiOutlineGlobeAlt,
  HiOutlineWallet,
  HiOutlineClipboardDocumentList,
  HiOutlineDocumentArrowDown,
  HiOutlineChartBarSquare,
  HiOutlineCircleStack,
  HiOutlineChartPie,
  HiOutlineDocumentChartBar,
  HiOutlineClock,
} from 'react-icons/hi2'

import './SettingSidebar.scss'

interface SettingSidebarPropsType {
  type: string
}

interface SidebarStructureType {
  [key: string]: SidebarItem[]
}

interface SidebarAccessType {
  [key: string]: any
}

interface SidebarItem {
  url: string
  title: string
  icon: React.ReactNode
  access?: SidebarAccessType
}

const sidebarStructure: SidebarStructureType = {
  settings: [
    {
      url: COMPANIES_ROUTE,
      title: 'Компании',
      icon: <HiOutlineBuildingOffice2 />,
    },
    {
      url: USERS_ROUTE,
      title: 'Пользователи',
      icon: <HiOutlineUserGroup />,
      access: {
        module: modulesDict.users,
        roles: [ROLES.SUPERADMIN, ROLES.ADMIN],
      },
    },
  ],
  prices: [
    {
      url: `${PRICES_ROUTE}/${MONITORING}`,
      title: 'Мониторинг',
      icon: <HiOutlineChartBarSquare />,
      access: {
        module: modulesDict.prices,
        permissions: [accessDict.read, accessDict.full_access],
      },
    },
    {
      url: `${PRICES_ROUTE}/${IMPORT_DATA}`,
      title: 'Импорт данных',
      icon: <HiOutlineDocumentArrowDown />,
      access: {
        module: modulesDict.prices,
        permissions: [accessDict.full_access],
      },
    },
    {
      url: `${PRICES_ROUTE}/${PRICE_DATA}`,
      title: 'Данные АТС',
      icon: <HiOutlineCircleStack />,
      access: {
        module: modulesDict.prices,
        permissions: [accessDict.read, accessDict.full_access],
      },
    },
    {
      url: `${PRICES_ROUTE}/${TARIFFS}`,
      title: 'Тарифы',
      icon: <HiOutlineChartPie />,
      access: {
        module: modulesDict.prices,
        permissions: [accessDict.read, accessDict.full_access],
      },
    },
    {
      url: `${PRICES_ROUTE}/${GP_DATA}`,
      title: 'Данные ГП',
      icon: <HiOutlineDocumentChartBar />,
      access: {
        module: modulesDict.prices,
        permissions: [accessDict.read, accessDict.full_access],
      },
    },
    {
      url: `${PRICES_ROUTE}/${HOURS_INTERVALS}`,
      title: 'Часы и интервалы СО ЕЭС',
      icon: <HiOutlineClock />,
      access: {
        module: modulesDict.prices,
        permissions: [accessDict.read, accessDict.full_access],
      },
    },
  ],
  directory: [
    {
      url: `${REFERENCE_BOOKS_ROUTE}/${CALENDAR}`,
      title: 'Календарь',
      icon: <HiOutlineCalendarDays />,
      access: {
        module: modulesDict.reference_books,
        permissions: [accessDict.read, accessDict.update_read],
      },
    },
    {
      url: `${REFERENCE_BOOKS_ROUTE}/${REGIONS}`,
      title: 'Регионы ',
      icon: <HiOutlineGlobeAlt />,
      access: {
        module: modulesDict.reference_books,
        permissions: [accessDict.read, accessDict.update_read],
      },
    },
    {
      url: `${REFERENCE_BOOKS_ROUTE}/${PRICE_CATEGORIES}`,
      title: 'Ценовые категории',
      icon: <HiOutlineChartBar />,
      access: {
        module: modulesDict.reference_books,
        permissions: [accessDict.read, accessDict.update_read],
      },
    },
    {
      url: `${REFERENCE_BOOKS_ROUTE}/${COUNTER_PARTIES}`,
      title: 'Контрагенты и договора',
      icon: <HiOutlineWallet />,
      access: {
        module: modulesDict.reference_books,
        permissions: [accessDict.read, accessDict.update_read],
      },
    },
  ],
}

export const SettingSidebar: React.FC<SettingSidebarPropsType> = (props) => {
  const { type } = props

  return (
    <>
      {sidebarStructure[type]?.map((item: SidebarItem, index: number) => {
        return (
          <React.Fragment key={index}>
            {item.access ? (
              <Access
                permissions={item.access.permissions || null}
                module={item.access?.module || null}
                roles={item.access?.roles || null}
              >
                <CustomLink
                  to={item.url}
                  key={item.title}
                  className="setting-sidebar-item"
                >
                  {item.icon} {item.title}
                </CustomLink>
              </Access>
            ) : (
              <CustomLink
                to={item.url}
                key={item.title}
                className="setting-sidebar-item"
              >
                {item.icon} {item.title}
              </CustomLink>
            )}
          </React.Fragment>
        )
      })}
    </>
  )
}
