import {
  parseISO,
  getMonth,
  getYear,
  addHours,
  format,
  addMinutes,
  parse,
} from 'date-fns'

import { months, rolesDict } from 'shared/dictionary'
import { EmptyValueReplacer } from 'shared/ui'

function replaceDotWithComma(value: number | string): string {
  const stringValue = value.toString()
  return stringValue.replace('.', ',')
}

export function normalizeValues<T>(value: T | any, type?: string): T | any {
  if (value === '') return <EmptyValueReplacer />

  switch (type) {
    case 'date':
      if (value) {
        const date = parseISO(value)
        const monthIndex = getMonth(date)
        const year = getYear(date)
        const formattedDate = `${months[monthIndex]} ${year}`
        return formattedDate
      }
      return 'Дата не определена'
    case 'roles':
      return rolesDict[value] || 'Роль не определена'
    case 'sum':
      return replaceDotWithComma(value)
    case 'date_only_year':
      if (value) {
        let date

        if (
          typeof value === 'number' ||
          (typeof value === 'string' && /^\d{4}$/.test(value))
        ) {
          date = new Date(`${value}-01-01`)
        } else if (
          typeof value === 'string' &&
          /^\d{4}-\d{2}-\d{2}$/.test(value)
        ) {
          date = parseISO(value)
        } else date = value

        if (date instanceof Date && !isNaN(date.getTime())) {
          const formattedDate = format(date, 'yyyy')
          return formattedDate
        }
      }
      return 'Дата не определена'
    case 'date_month_year':
      if (typeof value === 'string' && /^\d{2}\.\d{4}$/.test(value)) {
        return parse(value, 'MM.yyyy', new Date())
      } else if (typeof value === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(value))
        return parseISO(value)

      return 'Дата не определена'

    case 'date_utc_to_msc':
      if (value) {
        const utcDate = parseISO(value)
        const mscDate = addHours(utcDate, 3)
        const formattedDate = format(mscDate, 'dd.MM.yyyy')
        return formattedDate
      }
      return 'Дата не определена'
    case 'date_utc_to_msc_with_time':
      if (value) {
        const utcDate = parseISO(value)
        const mscDate = addHours(utcDate, 3)
        const formattedDate = format(mscDate, 'dd.MM.yyyy HH:mm')
        return formattedDate
      }
      return 'Дата не определена'
    case 'utc_to_local':
      if (value) {
        const utcDate = parseISO(value)
        const timezoneOffset = new Date().getTimezoneOffset()
        const localDate = addMinutes(utcDate, -timezoneOffset)
        const formattedDate = format(localDate, 'dd.MM.yyyy')
        return formattedDate
      }
      return 'Дата не определена'
    case 'date_month_string_with_year':
      let date
      if (value instanceof Date) date = value
      else if (typeof value === 'string') date = new Date(value)

      if (date) {
        const monthIndex = getMonth(date)
        const year = getYear(date)
        const formattedDate = `${months[monthIndex]} ${year}`
        return formattedDate
      }
      return 'Дата не определена'
    case 'utc_to_local_with_time':
      if (value) {
        const utcDate = parseISO(value)
        const timezoneOffset = new Date().getTimezoneOffset()
        const localDate = addMinutes(utcDate, -timezoneOffset)
        const formattedDate = format(localDate, 'dd.MM.yyyy HH:mm')
        return formattedDate
      }
      return 'Дата не определена'
    default:
      return value
  }
}
