type ReferenceParams = {
  subject_id?: string
  gs_activity_areas_id?: string | null
  price_zone_id?: string | null
  date?: string
  ue_system_id?: string
}

type Reference = {
  link: string
  page?: string
  params?: ReferenceParams
}

export function referenceGenerateUrl(reference: Reference): string {
  const { link, page, params } = reference
  let url = link

  const queryParams = new URLSearchParams()

  if (page) queryParams.append('pageType', page)
  if (params?.subject_id) queryParams.append('subject_id', params.subject_id)
  if (params?.gs_activity_areas_id)
    queryParams.append(
      'gs_activity_areas_id',
      params.gs_activity_areas_id ?? '',
    )
  if (params?.date) queryParams.append('date', params.date)
  if (params?.ue_system_id)
    queryParams.append('ue_system_id', params.ue_system_id)
  if (params?.price_zone_id)
    queryParams.append('price_zone_id', params.price_zone_id)

  const queryString = queryParams.toString()
  if (queryString) {
    url += `?${queryString}`
  }

  return url
}
