import { format, parse, isValid } from 'date-fns'

/**
 * Конвертирует входную дату в строковый формат 'yyyy-MM-dd' для API.
 * Принимает дату в форматах 'dd.MM.yyyy', 'yyyy.MM.dd', 'MM.yyyy' или как объект Date.
 * Возвращает строку в формате 'yyyy-MM-dd' или null, если дата некорректна.
 *
 * @param date - Входная дата в виде строки или объекта Date.
 * @returns Строка в формате 'yyyy-MM-dd' или null.
 */

export function convertDateForAPI(date: string | Date | null): string | null {
  if (!date) return null

  if (typeof date === 'string') {
    let parsedDate = parse(date, 'dd.MM.yyyy', new Date())
    if (isValid(parsedDate)) {
      return format(parsedDate, 'yyyy-MM-dd')
    }

    parsedDate = parse(date, 'yyyy.MM.dd', new Date())
    if (isValid(parsedDate)) {
      return format(parsedDate, 'yyyy-MM-dd')
    }

    parsedDate = parse(date, 'MM.yyyy', new Date())
    if (isValid(parsedDate)) {
      return format(parsedDate, 'yyyy-MM-01')
    }

    const directParsedDate = new Date(date)
    if (isValid(directParsedDate)) {
      return format(directParsedDate, 'yyyy-MM-dd')
    }
  } else if (date instanceof Date && isValid(date)) {
    return format(date, 'yyyy-MM-dd')
  }

  return null
}
