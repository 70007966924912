import React, { memo } from 'react'
import { groupBy, map, fromPairs } from 'lodash'
import { Box } from '@chakra-ui/react'

import { GetDataViewSVNCEMByHourData } from 'shared/models'
import { TableEntity } from './TableEntity'
import { DataCharts } from './DataCharts'
import { ConsumptionGraph } from './DataChartsByDay'

interface IDateHourTableProps {
  tableData: GetDataViewSVNCEMByHourData[]
}

export const DateHourTable: React.FC<IDateHourTableProps> = memo(
  ({ tableData }) => {
    const groupedByDate = groupBy(tableData, 'date')
    const dataForTable = map(groupedByDate, (entries, date) => {
      const hours = fromPairs(
        entries.map((entry) => [entry.hour_number, entry.price]),
      )
      return { date, ...hours }
    })

    const formatDataForRecharts = dataForTable?.map((day) => ({
      date: day.date,
      ...Object.keys(day).reduce((acc, key) => {
        if (key !== 'date') {
          acc[key + ':00'] = parseFloat(day[key])
        }
        return acc
      }, {}),
    }))

    return (
      <Box margin={'-17px'} mt={'0px'}>
        <TableEntity tableData={dataForTable} />
      </Box>
    )
  },
)
