import { useEffect, useState } from 'react'
import { Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'

import { useUrlParams } from 'shared/utils'
import { HoursSOEAS, DayZoneIntervals } from 'widgets'

export const HoursAndIntervalsPage = () => {
  const {
    query_date,
    query_price_zone_id,
    query_pageType,
    query_ue_system_id,
  } = useUrlParams()

  const [tabIndex, setTabIndex] = useState<number>(0)

  useEffect(() => {
    if (query_pageType === 'Часы СО ЕЭС') setTabIndex(0)
    if (query_pageType === 'Интервалы зон суток') setTabIndex(1)
  }, [query_pageType])

  const hoursSOEASProps = { query_date, query_price_zone_id }

  const dayZoneIntervalsProps = { query_date, query_ue_system_id }

  const handleTabsChange = (index) => setTabIndex(index)

  return (
    <Tabs isManual index={tabIndex} onChange={handleTabsChange}>
      <TabList p={'10px'} pb={0}>
        <Tab>
          <Text fontSize={'18px'}>Часы СО ЕЭС</Text>
        </Tab>
        <Tab>
          <Text fontSize={'18px'}>Интервалы зон суток</Text>
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel padding={0}>
          <HoursSOEAS {...hoursSOEASProps} />
        </TabPanel>
        <TabPanel padding={0}>
          <DayZoneIntervals {...dayZoneIntervalsProps} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}
