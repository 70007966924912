/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo } from 'react'

import { BasicTable } from 'shared/ui'
import { TableStructure } from './TableStructure'
import { DayZoneIntervalsTableRow } from '../../types/types'
import { Box } from '@chakra-ui/react'

interface ITableEntityProps {
  tableData: DayZoneIntervalsTableRow[]
  editMode: boolean
  setTableData: any
}

export const TableEntity: React.FC<ITableEntityProps> = memo(
  (props): JSX.Element => {
    const { tableData = [], editMode, setTableData } = props

    const columns = TableStructure({ editMode, setTableData, tableData })

    const styles: React.CSSProperties = {
      textAlign: 'center' as 'center',
      fontSize: '14px',
      background: 'white',
    }

    const intervalsStyles: React.CSSProperties = {
      border: '1px solid rgb(241, 241, 241)',
    }

    const CustomHeader = () => (
      <>
        <tr>
          <th colSpan={2} style={{ ...styles, ...intervalsStyles }}></th>
          <th colSpan={23} style={{ ...styles, ...intervalsStyles }}>
            Интервалы
          </th>
          <th colSpan={1} style={{ ...styles, ...intervalsStyles }}></th>
        </tr>
        <tr>
          <th
            colSpan={1}
            className="custom-white-bg"
            style={{ ...styles, ...intervalsStyles }}
          >
            с
          </th>
          <th
            colSpan={1}
            className="custom-white-bg"
            style={{ ...styles, ...intervalsStyles }}
          ></th>
          {Array.from({ length: 24 }).map((_, i) => (
            <th
              key={`from-hour-${i}`}
              colSpan={1}
              className="custom-white-bg"
              style={{ ...styles, ...intervalsStyles }}
            >
              {String(i).padStart(2, '0')}:00
            </th>
          ))}
        </tr>
        <tr>
          <th
            colSpan={1}
            className="custom-white-bg"
            style={{ ...styles, ...intervalsStyles }}
          >
            по
          </th>
          <th
            colSpan={1}
            className="custom-white-bg"
            style={{ ...styles, ...intervalsStyles }}
          ></th>
          {Array.from({ length: 24 }).map((_, i) => (
            <th
              key={`to-hour-${i}`}
              colSpan={1}
              className="custom-white-bg"
              style={{ ...styles, ...intervalsStyles }}
            >
              {String(i + 1 === 24 ? 0 : i + 1).padStart(2, '0')}:00
            </th>
          ))}
        </tr>
      </>
    )

    const tableProps = {
      tableStyles: {
        tableMaxHeight: '70vh',
        tableWidth: '100%',
      },
      data: tableData,
      columns,
      emptyText: 'Данные отсутствуют',
      loading: false,
      showTableMenu: false,
      smallShadow: true,
      lightHeader: true,
      customHeader: () => <CustomHeader />,
    }

    return (
      <>
        <BasicTable {...tableProps} />
      </>
    )
  },
  (prevProps, nextProps) => {
    return (
      prevProps.tableData === nextProps.tableData &&
      prevProps.editMode === nextProps.editMode
    )
  },
)
