import { useForm, useFieldArray } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'

const SCHEMA_VALIDATION = z.object({
  template_name: z.string().nonempty('Имя обязательное поле'),
  items: z
    .array(
      z.object({
        file_type: z.string().nonempty('Тип файла обязателен'),
        activity_zone: z.string().optional(),
      }),
    )
    .min(1, 'Необходимо добавить хотя бы один элемент')
    .superRefine((items, ctx) => {
      items.forEach((item, index) => {
        if (item.file_type !== 'tariff_fgc' && !item.activity_zone) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['items', index, 'activity_zone'],
            message:
              'Зона деятельности обязательна, если тип файла не Tariff FGC',
          })
        }
      })
    }),
})

export interface CreateATCTemplateFormData {
  template_name?: string
  items?: { file_type?: string; activity_zone?: string }[]
}

export const useCreateATCTemplateForm = () => {
  const { register, handleSubmit, formState, control, watch, reset } =
    useForm<CreateATCTemplateFormData>({
      resolver: zodResolver(SCHEMA_VALIDATION),
      defaultValues: {
        items: [{ file_type: '', activity_zone: '' }],
      },
      mode: 'all',
    })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
  })

  const watchedFields = watch()
  const items = watch('items')
  const { isValid, errors, isDirty } = formState

  return {
    register,
    handleSubmit,
    errors,
    isDirty,
    isValid,
    control,
    itemsFields: fields,
    appendItem: append,
    removeItem: remove,
    watchedFields,
    reset,
    items,
  }
}
