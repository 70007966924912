import { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Button, Flex, Grid, SimpleGrid, Text } from '@chakra-ui/react'

import { GetAllACTemplatesResponse } from 'shared/models'

import {
  EditATCTemplateFormData,
  useEditATCTemplateForm,
} from './EditATCTemplateItemForm'
import { useGetAllActivityAreas } from 'entities/referenceBooks'
import { DeleteATCTemplate } from 'features/prices/deleteATCTemplate'

import {
  EditButton,
  FormInputBlock,
  FormInputControl,
  SelectInputForm,
  SubmitCancelButtons,
  UploadFileInfoView,
} from 'shared/ui'
import { useUpdateATCTemplate } from '../models/editATCTemplateServie'

// misc
import { HiMiniPlusCircle, HiOutlineArchiveBoxXMark } from 'react-icons/hi2'
import { reportTypes, reportTypesTranslate } from 'shared/dictionary'
import { omitBy } from 'lodash'

interface EditATCTemplateItemProps extends GetAllACTemplatesResponse {}

export const EditATCTemplateItem: React.FC<EditATCTemplateItemProps> = (
  props,
): React.ReactElement => {
  const { added_at, data, template_name, user_name } = props

  const [isEdit, setIsEdit] = useState<boolean>(false)

  const { activityAreasData = [] } = useGetAllActivityAreas({
    enabled: true,
  })

  const { mutate: updateATCTemplate, isLoading } = useUpdateATCTemplate()

  const {
    register,
    handleSubmit,
    errors,
    reset,
    control,
    watchedFields,
    isDirty,
    itemsFields,
    appendItem,
    removeItem,
    isValid,
    items,
  } = useEditATCTemplateForm()

  useEffect(() => {
    if (template_name && data) {
      reset({
        template_name: template_name,
        items: data,
      })
    }
  }, [template_name, data])

  const getAvailableActivityAreas = useCallback(
    (
      currentType: string,
      currentIndex: number,
    ): { value: string; label: string }[] => {
      const occupied = new Set(
        items
          .filter(
            (item, idx) =>
              item.template_type === currentType && idx !== currentIndex,
          )
          .map((item) => item.gs_activity_areas_id),
      )
      return activityAreasData.filter((area) => !occupied.has(area.value))
    },
    [items, activityAreasData],
  )

  const reportTypesList = Object.keys(reportTypes).map((key) => ({
    value: reportTypes[key],
    label: reportTypesTranslate[key],
  }))

  const handleEditClick = useCallback(() => {
    setIsEdit(!isEdit)
  }, [isEdit])

  const onSubmit = useCallback(
    (submitProps: EditATCTemplateFormData) => {
      // updateATCTemplate({
      //   template_name: submitProps.template_name,
      //   old_name: template_name,
      //   data: submitProps.items as any,
      //   successAction: handleEditClick,
      // })

      updateATCTemplate({
        template_name: submitProps.template_name,
        old_name: template_name,
        data: submitProps.items.map((item) => {
          return omitBy(item, (value, key) => {
            return (
              item.template_type === 'tariff_fgc' &&
              key === 'gs_activity_areas_id'
            )
          })
        }) as any,
        successAction: handleEditClick,
      })
    },
    [updateATCTemplate, template_name, handleEditClick],
  )

  const commonInputProps = useMemo(
    () => ({
      control,
      register,
      errors,
      watchedFields,
      size: 'sm' as 'sm',
      smallErrorTextInside: true,
      isRequired: true,
    }),
    [register, errors, watchedFields, control],
  )

  // const disableAddButton = items.some(
  //   (item) => !item.template_type || !item.gs_activity_areas_id,
  // )

  const disableAddButton = items.some(
    (item) =>
      item.template_type === '' ||
      (item.template_type !== 'tariff_fgc' && item.gs_activity_areas_id === ''),
  )

  const uploadInfo = useMemo(
    () => ({
      user: user_name,
      added_at,
      inline: true,
    }),
    [user_name, added_at],
  )

  const getActivityAreaName = useCallback(
    (activityArea: string): string => {
      const area = activityAreasData.find((item) => item.value === activityArea)
      return area?.label || ''
    },
    [activityAreasData],
  )

  const getAvailableReportTypes = useCallback(
    (currentAreaId, currentIndex) => {
      const usedTypes = new Set(
        items
          .filter(
            (item, idx) =>
              item.gs_activity_areas_id === currentAreaId &&
              idx !== currentIndex,
          )
          .map((item) => item.template_type),
      )

      return reportTypesList.filter((type) => !usedTypes.has(type.value))
    },
    [items, reportTypesList],
  )

  const handleCancel = useCallback(() => {
    reset()
  }, [reset])

  return (
    <Box position={'relative'}>
      <UploadFileInfoView {...uploadInfo} />
      <Box mt={'10px'} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex
          w="100%"
          justifyContent="flex-end"
          position={'absolute'}
          right={'10px'}
          top={'10px'}
        >
          <EditButton
            handleEditClick={handleEditClick}
            isEdit={isEdit}
            size={'xs'}
          />
        </Flex>
        <Box maxW={'1000Px'}>
          <Box mr={'35px'} w={'470px'}>
            <FormInputBlock
              titleWidth={'175px'}
              allowEdit={isEdit}
              title={'Название шаблона:'}
              edit={isEdit}
              value={template_name}
            >
              <FormInputControl
                name="template_name"
                placeholder="Наименование"
                type="text"
                {...commonInputProps}
              />
            </FormInputBlock>
          </Box>

          <Box mt={'15px'} />

          <Box border={'1px solid #dfe0eb'} borderRadius={'5px'} p={3}>
            <SimpleGrid columns={1} spacing={3}>
              {itemsFields.map((field, index) => {
                const availableActivityAreas = getAvailableActivityAreas(
                  items[index].template_type,
                  index,
                )
                const availableReportTypes = getAvailableReportTypes(
                  items[index].gs_activity_areas_id,
                  index,
                )

                return (
                  <Box key={field.id}>
                    <Grid templateColumns="1fr 1fr 30px" columnGap={'15px'}>
                      <FormInputBlock
                        titleWidth={'160px'}
                        title={'Тип файла'}
                        allowEdit={true}
                        edit={isEdit}
                        value={reportTypesTranslate[items[index].template_type]}
                      >
                        <SelectInputForm
                          placeholder="Тип файла"
                          data={availableReportTypes}
                          name={`items.${index}.template_type`}
                          isClearable={true}
                          getOptionValue={(option) =>
                            option ? option.value : ''
                          }
                          {...commonInputProps}
                        />
                      </FormInputBlock>

                      {items[index].template_type !== 'tariff_fgc' && (
                        <FormInputBlock
                          titleWidth={'150px'}
                          title={'Зона деятельности'}
                          allowEdit={true}
                          edit={isEdit}
                          value={getActivityAreaName(
                            items[index].gs_activity_areas_id,
                          )}
                        >
                          <SelectInputForm
                            isDisabled={!items[index].template_type}
                            isReadOnly={!items[index].template_type}
                            placeholder="Зона деятельности"
                            data={availableActivityAreas}
                            name={`items.${index}.gs_activity_areas_id`}
                            isClearable={true}
                            getOptionValue={(option) =>
                              option ? option.value : ''
                            }
                            {...commonInputProps}
                          />
                        </FormInputBlock>
                      )}

                      {isEdit && (
                        <Flex
                          w="100%"
                          justifyContent={'flex-end'}
                          alignItems={'end'}
                          pb={'7px'}
                        >
                          <Flex
                            w={'100%'}
                            justifyContent={
                              items[index].template_type !== 'tariff_fgc'
                                ? 'center'
                                : 'flex-start'
                            }
                            ml="15px"
                            className="icon-delete"
                            fontSize="20px"
                            cursor="pointer"
                            color="#c2c2c2"
                            _hover={{
                              cursor: 'pointer',
                              color: '#bc343f',
                            }}
                            onClick={() => removeItem(index)}
                          >
                            <HiOutlineArchiveBoxXMark />
                          </Flex>
                        </Flex>
                      )}
                    </Grid>
                  </Box>
                )
              })}
            </SimpleGrid>
            {isEdit && (
              <Box mt="15px">
                <Button
                  w={'fit-content'}
                  mr="10px"
                  position={'relative'}
                  size={'xs'}
                  bg="blue.700"
                  color="white"
                  display={'flex'}
                  alignItems={'center'}
                  onClick={() =>
                    appendItem({ template_type: '', gs_activity_areas_id: '' })
                  }
                  isDisabled={disableAddButton}
                  _hover={{
                    bg: 'blue.600',
                  }}
                >
                  <Box fontSize={'18px'} mr={'5px'}>
                    <HiMiniPlusCircle />
                  </Box>
                  <Text position={'relative'}>Добавить тип</Text>
                </Button>
              </Box>
            )}
          </Box>
        </Box>
        {isDirty && isEdit && (
          <Box mt={'25px'}>
            <SubmitCancelButtons
              isDirty={isDirty}
              isUpdating={isLoading}
              handleCancel={handleCancel}
              isDisabled={!isValid || itemsFields.length === 0}
            />
          </Box>
        )}
      </form>
      {isEdit && (
        <Box mt={'25px'}>
          <DeleteATCTemplate template_name={template_name} />
        </Box>
      )}
    </Box>
  )
}
