import { useForm, useFieldArray } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import * as z from 'zod'

const SCHEMA_VALIDATION = z.object({
  template_name: z.string().nonempty('Имя обязательное поле'),
  items: z
    .array(
      z.object({
        gs_activity_areas_ids: z.string().optional(),
      }),
    )
    .min(1, 'Необходимо добавить хотя бы один элемент'),
})

export interface EditMonitoringTemplateFormData {
  template_name?: string
  items?: { gs_activity_areas_ids?: string }[]
}

export const useEditMonitoringTemplateForm = () => {
  const { register, handleSubmit, formState, control, watch, reset } =
    useForm<EditMonitoringTemplateFormData>({
      resolver: zodResolver(SCHEMA_VALIDATION),
      defaultValues: {
        items: [{ gs_activity_areas_ids: '' }],
      },
      mode: 'all',
    })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
  })

  const watchedFields = watch()
  const items = watch('items')
  const { isValid, errors, isDirty } = formState

  return {
    register,
    handleSubmit,
    errors,
    isDirty,
    isValid,
    control,
    itemsFields: fields,
    appendItem: append,
    removeItem: remove,
    watchedFields,
    reset,
    items,
  }
}
