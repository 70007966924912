import { useQuery } from 'react-query'

import {
  ExtendedAxiosError,
  GetSalesMarkupsData,
  GetUeSystemOperatorHoursResponse,
} from 'shared/models'

import { queryKeys } from 'shared/constants'
import { PricesServiceService } from 'shared/services'
import { toasts } from 'shared/utils'

interface UseGetUeSystemOperatorHoursResult {
  data: GetUeSystemOperatorHoursResponse[] | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
  refetch: () => void
}

interface UseGetUeSystemOperatorHours {
  enabled: boolean
  price_zone_id: string
  date_period: string
  successAction?: () => void
  errorAction?: () => void
}

export function useGetUeSystemOperatorHoursService({
  enabled,
  price_zone_id,
  date_period,
  successAction,
  errorAction,
}: UseGetUeSystemOperatorHours): UseGetUeSystemOperatorHoursResult {
  const { data, isError, isFetching, isLoading, refetch } = useQuery<
    GetUeSystemOperatorHoursResponse[]
  >({
    queryKey: [
      queryKeys.prices.ueSystemOperatorHours,
      price_zone_id,
      date_period,
    ],
    queryFn: () =>
      PricesServiceService.getGetUeSystemOperatorHours({
        price_zone_id,
        year: date_period,
      }).then((response) => response.data),
    enabled,
    onError: (error: ExtendedAxiosError) => {
      if (errorAction) errorAction()
      toasts.error({
        title: 'Произошла ошибка',
        description:
          error?.response?.data?.detail || 'Данные не были загружены',
      })
    },
    onSuccess: () => {
      if (successAction) successAction()
    },
  })

  return {
    data: data,
    isError,
    isFetching,
    isLoading,
    refetch,
  }
}
