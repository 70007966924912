import { useQuery } from 'react-query'

import {
  ExtendedAxiosError,
  GetMonitoringByZoneActivityResponse,
} from 'shared/models'

import { queryKeys } from 'shared/constants'
import { MonitoringPricesService } from 'shared/services'
import { toasts } from 'shared/utils'

interface UseGetMonitoringByZoneActivityResult {
  data: GetMonitoringByZoneActivityResponse[] | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
  refetch: () => void
}

type GeneratedData = {
  month: string
  gs_activity_areas_id: string
}

interface UseGetMonitoringByZoneActivity {
  enabled: boolean
  date_period: string
  gs_activity_areas_id: string
  data: GeneratedData[]
  successAction?: () => void
  errorAction?: () => void
}
export function useGetMonitoringByMonthService({
  enabled,
  date_period,
  successAction,
  errorAction,
  gs_activity_areas_id,
  data: generatedData,
}: UseGetMonitoringByZoneActivity): UseGetMonitoringByZoneActivityResult {
  const { data, isError, isFetching, isLoading, refetch } = useQuery<
    GetMonitoringByZoneActivityResponse[]
  >({
    queryKey: [
      queryKeys.prices.monitoringByMonth,
      date_period,
      gs_activity_areas_id,
    ],
    queryFn: () =>
      MonitoringPricesService.getMonitoringByZoneActivity({
        generatedData,
      }).then((response) => response.data),
    enabled,
    onError: (error: ExtendedAxiosError) => {
      if (errorAction) errorAction()
      toasts.error({
        title: 'Произошла ошибка',
        description:
          error?.response?.data?.detail || 'Данные не были загружены',
      })
    },
    onSuccess: () => {
      if (successAction) successAction()
    },
  })

  return {
    data: data,
    isError,
    isFetching,
    isLoading,
    refetch,
  }
}
