import { useQuery } from 'react-query'

import { ExtendedAxiosError, GetDayZoneIntervalsResponse } from 'shared/models'

import { queryKeys } from 'shared/constants'
import { PricesServiceService } from 'shared/services'
import { toasts } from 'shared/utils'

interface UseGetDayZoneIntervalsResult {
  data: GetDayZoneIntervalsResponse[] | undefined
  isError: boolean
  isFetching: boolean
  isLoading: boolean
  refetch: () => void
}

interface UseGetDayZoneIntervals {
  enabled: boolean
  ue_system_id: string
  date_period: string
  successAction?: () => void
  errorAction?: () => void
}

export function useUseGetDayZoneIntervalsService({
  enabled,
  ue_system_id,
  date_period,
  successAction,
  errorAction,
}: UseGetDayZoneIntervals): UseGetDayZoneIntervalsResult {
  const { data, isError, isFetching, isLoading, refetch } = useQuery<
    GetDayZoneIntervalsResponse[]
  >({
    queryKey: [
      queryKeys.prices.dayZoneIntervalsService,
      ue_system_id,
      date_period,
    ],
    queryFn: () =>
      PricesServiceService.getDayZoneIntervals({
        ue_system_id,
        year: date_period,
      }).then((response) => response.data),
    enabled,
    onError: (error: ExtendedAxiosError) => {
      if (errorAction) errorAction()
      toasts.error({
        title: 'Произошла ошибка',
        description:
          error?.response?.data?.detail || 'Данные не были загружены',
      })
    },
    onSuccess: () => {
      if (successAction) successAction()
    },
  })

  return {
    data: data,
    isError,
    isFetching,
    isLoading,
    refetch,
  }
}
