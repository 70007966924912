import { AxiosError } from 'axios'
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query'

import { queryKeys } from 'shared/constants'
import { ExtendedAxiosError } from 'shared/models'
import { MonitoringPricesService } from 'shared/services'
import { toasts } from 'shared/utils'

interface DeleteMonitoringTemplate {
  monitoring_template_name: string
  successAction: () => void
}
export function useDeleteMonitoringTemplate(): {
  mutate: UseMutateFunction<void, AxiosError, DeleteMonitoringTemplate, unknown>
  isLoading: boolean
} {
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation<
    void,
    AxiosError,
    DeleteMonitoringTemplate
  >(
    ({ monitoring_template_name }: DeleteMonitoringTemplate) =>
      MonitoringPricesService.deleteMonitoringTemplate({
        monitoring_template_name,
      }).then(() => {}),
    {
      onSuccess: (_, variables: DeleteMonitoringTemplate) => {
        if (variables.successAction) variables.successAction()

        toasts.success({
          title: 'Успех',
          description: `Шаблон успешно удален`,
        })
        queryClient.invalidateQueries([queryKeys.prices.allMonitoringTemplates])
      },
      onError: (error: ExtendedAxiosError) => {
        toasts.error({
          title: 'Произошла ошибка',
          description: error.response.data.detail || 'Шаблон не был удален',
        })
      },
    },
  )

  return { mutate, isLoading }
}
