import React, { useMemo, useState, memo, useEffect } from 'react'

import {
  Box,
  Flex,
  Text,
  Button,
  TabPanels,
  Tabs,
  TabPanel,
} from '@chakra-ui/react'

import { EditGPData } from 'features/prices'
import { useGetGPDataService } from 'entities/prices'
import { useGetAllActivityAreas } from 'entities/referenceBooks'
import { DatePickerField, FormInputBlock, SelectInputForm } from 'shared/ui'
import { normalizeValues } from 'shared/utils'

import { GpDataType } from '../types/types'
import { useGPDataForm } from './GPDataForm'

// misc
import { HiOutlineTableCells } from 'react-icons/hi2'

interface GPDataProps {
  query_date: string
  query_gs_activity_areas_id: string
}

export const GPData: React.FC<GPDataProps> = memo(
  ({ query_date, query_gs_activity_areas_id }): React.ReactElement => {
    const [dataType, setDataType] = useState<GpDataType>(GpDataType.enter)
    const [fetchEnabled, setFetchEnabled] = useState<{
      enabled: boolean
    }>({
      enabled: false,
    })

    const { activityAreasData } = useGetAllActivityAreas({
      enabled: true,
    })

    const {
      register,
      handleSubmit,
      errors,
      reset,
      isDirty,
      watchedFields,
      control,
      setValue,
      setError,
      watch,
      isValid,
    } = useGPDataForm()

    const year = normalizeValues(watchedFields.date_period, 'date_only_year')

    const { data, isLoading, isFetching, refetch } = useGetGPDataService({
      enabled: fetchEnabled.enabled,
      gs_activity_areas_id: watchedFields.gs_activity_areas_id,
      date_period: year,
    })

    useEffect(() => {
      if (query_date || query_gs_activity_areas_id) {
        reset({
          date_period: query_date
            ? normalizeValues(query_date, 'date_only_year')
            : null,
          gs_activity_areas_id: query_gs_activity_areas_id
            ? query_gs_activity_areas_id
            : null,
        })
      }
    }, [query_date, query_gs_activity_areas_id])

    const commonInputProps = useMemo(
      () => ({
        control,
        register,
        errors,
        watchedFields,
        size: 'sm' as 'sm',
        smallErrorTextInside: true,
        isRequired: true,
      }),
      [register, errors, watchedFields, control],
    )

    const editGPDataProps = {
      data,
      year,
      gs_activity_areas_id: watchedFields.gs_activity_areas_id,
    }

    const handleFetchData = () => refetch()

    return (
      <Box p="2">
        <form>
          <Box ml="10px">
            <Flex alignItems={'center'}>
              <Box mr="25px" w={'150px'}>
                <FormInputBlock
                  titleWidth={'60px'}
                  allowEdit={true}
                  title={'Период'}
                  edit={true}
                >
                  <DatePickerField
                    setInitialDate
                    isReadOnly
                    showOnlyYearPicker
                    showYearPicker
                    format="yyyy"
                    showMonthYearPicker
                    name={'date_period'}
                    placeholder={'Период'}
                    type={'text'}
                    theme="yearPicker"
                    {...commonInputProps}
                  />
                </FormInputBlock>
              </Box>
              <Box w={'480px'}>
                <FormInputBlock
                  titleWidth={'180px'}
                  allowEdit={true}
                  edit
                  title={'Зона деятельности ГП'}
                >
                  <SelectInputForm
                    placeholder="Выберите зона деятельности ГП"
                    getOptionValue={(option) => (option ? option.value : '')}
                    name="gs_activity_areas_id"
                    data={activityAreasData || []}
                    isClearable={true}
                    {...commonInputProps}
                  />
                </FormInputBlock>
              </Box>
            </Flex>
            <Box mt="20px">
              <Flex>
                <Button
                  w={'135px'}
                  size="xs"
                  borderRadius={0}
                  borderLeftRadius={'5px'}
                  border={'1px solid #ececec'}
                  borderRight={'none'}
                  _hover={{ bg: '#3285d2', color: 'white' }}
                  bg={dataType === GpDataType.enter ? '#2d70af' : '#f8f8f8'}
                  color={dataType === GpDataType.enter ? 'white' : 'black'}
                  onClick={() => setDataType(GpDataType.enter)}
                >
                  Ввод данных
                </Button>
                <Button
                  w={'135px'}
                  size="xs"
                  borderRadius={0}
                  borderRightRadius={'5px'}
                  border={'1px solid #ececec'}
                  _hover={{ bg: '#3285d2', color: 'white' }}
                  bg={dataType === GpDataType.calculate ? '#2d70af' : '#f8f8f8'}
                  color={dataType === GpDataType.calculate ? 'white' : 'black'}
                  onClick={() => setDataType(GpDataType.calculate)}
                >
                  Расчет
                </Button>

                <Box ml="10px">
                  <Button
                    w={'135px'}
                    position={'relative'}
                    size={'xs'}
                    color="white"
                    display={'flex'}
                    alignItems={'center'}
                    onClick={handleFetchData}
                    colorScheme="teal"
                    isDisabled={!isValid}
                    isLoading={isLoading || isFetching}
                  >
                    <Box fontSize={'18px'} mr={'5px'}>
                      <HiOutlineTableCells />
                    </Box>
                    <Text position={'relative'}>Показать</Text>
                  </Button>
                </Box>
              </Flex>
            </Box>
          </Box>

          {data && (
            <Box
              mt="15px"
              p="2"
              border={'1px solid #dfe0eb'}
              borderRadius={'5px'}
            >
              <Tabs index={dataType === GpDataType.enter ? 0 : 1} isManual>
                <TabPanels>
                  <TabPanel padding={0}>
                    <EditGPData {...editGPDataProps} />
                  </TabPanel>
                  <TabPanel padding={0}>Calculate</TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          )}
        </form>
      </Box>
    )
  },
)
