import React, { useMemo, useState, memo, useEffect } from 'react'

import { Box, Flex, Text, Button } from '@chakra-ui/react'

import { DownloadTariffsReport, EditTransService } from 'features/prices'
import { useGetTransServiceService } from 'entities/prices'
import { useGetAllSubjects } from 'entities/referenceBooks'

import { DatePickerField, FormInputBlock, SelectInputForm } from 'shared/ui'
import { normalizeValues } from 'shared/utils'

import { useDataViewTransServiceForm } from './DataViewTransServiceForm'
import { TransTableType } from '../types/types'

// misc
import { HiOutlineTableCells } from 'react-icons/hi2'

interface DataViewTransServiceProps {
  query_subject_id: string
  query_date: string
}

export const DataViewTransService: React.FC<DataViewTransServiceProps> = memo(
  ({ query_subject_id, query_date }): JSX.Element => {
    const [transTableType, setTransTableType] = useState<TransTableType>(
      TransTableType.oneParts,
    )

    const [fetchEnabled, setFetchEnabled] = useState<{
      enabled: boolean
    }>({
      enabled: false,
    })

    const { subjectsData } = useGetAllSubjects({
      enabled: true,
    })

    const { register, errors, watchedFields, control, isValid, reset } =
      useDataViewTransServiceForm()

    const year = normalizeValues(watchedFields.date_period, 'date_only_year')

    const { data, isLoading, isFetching, refetch } = useGetTransServiceService({
      enabled: fetchEnabled.enabled,
      subject_id: watchedFields.subject_id,
      date_period: year,
    })

    useEffect(() => {
      if (query_date || query_subject_id) {
        reset({
          date_period: query_date
            ? normalizeValues(query_date, 'date_only_year')
            : null,
          subject_id: query_subject_id ? query_subject_id : null,
        })
      }
    }, [query_date, query_subject_id])

    const commonInputProps = useMemo(
      () => ({
        control,
        register,
        errors,
        watchedFields,
        size: 'sm' as 'sm',
        smallErrorTextInside: true,
        isRequired: true,
      }),
      [register, errors, watchedFields, control],
    )

    const editSalesTransServiceProps = {
      data,
      downloadReportSlot: <>Download report</>,
      year,
      subject_id: watchedFields.subject_id,
      transTableType,
      setTransTableType,
    }

    const downloadTariffsReportProps = {
      year,
      subject_id: watchedFields.subject_id,
      report_type: 'trans_svc_by_subjects',
      isDisabled: watchedFields.subject_id && !watchedFields.date_period,
    }

    const handleFetchData = () => refetch()

    return (
      <Box p="2">
        <form>
          <Box ml="10px">
            <Flex alignItems={'center'}>
              <Box mr="25px" w={'150px'}>
                <FormInputBlock
                  titleWidth={'60px'}
                  allowEdit={true}
                  title={'Период'}
                  edit={true}
                >
                  <DatePickerField
                    setInitialDate
                    isReadOnly
                    showOnlyYearPicker
                    showYearPicker
                    format="yyyy"
                    showMonthYearPicker
                    name={'date_period'}
                    placeholder={'Период'}
                    type={'text'}
                    theme="yearPicker"
                    {...commonInputProps}
                  />
                </FormInputBlock>
              </Box>
              <Box w={'480px'}>
                <FormInputBlock
                  titleWidth={'90px'}
                  allowEdit={true}
                  edit
                  title={'Субъект РФ'}
                >
                  <SelectInputForm
                    placeholder="Выберите Субъект РФ"
                    getOptionValue={(option) => (option ? option.value : '')}
                    name="subject_id"
                    data={subjectsData || []}
                    isClearable={true}
                    {...commonInputProps}
                  />
                </FormInputBlock>
              </Box>
            </Flex>
            <Box mt="20px">
              <Flex>
                <Box>
                  <Button
                    w={'135px'}
                    position={'relative'}
                    size={'xs'}
                    color="white"
                    display={'flex'}
                    alignItems={'center'}
                    onClick={handleFetchData}
                    colorScheme="teal"
                    isDisabled={!isValid}
                    isLoading={isLoading || isFetching}
                  >
                    <Box fontSize={'18px'} mr={'5px'}>
                      <HiOutlineTableCells />
                    </Box>
                    <Text position={'relative'}>Показать</Text>
                  </Button>
                </Box>
              </Flex>
            </Box>
          </Box>

          {data && (
            <Box
              mt="15px"
              p="2"
              border={'1px solid #dfe0eb'}
              borderRadius={'5px'}
            >
              <EditTransService
                {...editSalesTransServiceProps}
                downloadReportSlot={
                  <Box ml={'15px'}>
                    <DownloadTariffsReport {...downloadTariffsReportProps} />
                  </Box>
                }
              />
            </Box>
          )}
        </form>
      </Box>
    )
  },
)
