/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react'

import { BasicTable } from 'shared/ui'

import { TableStructure } from './TableStructure'

interface ITableEntityProps {
  tableData: any
}

export const TableEntity: React.FC<ITableEntityProps> = (props) => {
  const { tableData = [] } = props

  const columns = TableStructure()

  const data = useMemo(() => tableData, [tableData])

  const tableProps = useMemo(
    () => ({
      tableStyles: {
        tableMaxHeight: '75vh',
        tableWidth: '100%',
      },
      data,
      columns,
      emptyText: 'Данные отсутствуют',
      loading: false,
      showTableMenu: false,
      smallShadow: true,
      lightHeader: true,
      smallHeader: false,
      resizable: false,
    }),
    [data, columns],
  )

  return (
    <>
      <BasicTable {...tableProps} />
    </>
  )
}
