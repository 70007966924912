import { useEffect, useMemo, useState } from 'react'
import { Box, Flex, Text, Button, FormLabel, Switch } from '@chakra-ui/react'
import {
  startOfMonth,
  subMonths,
  addMonths,
  eachMonthOfInterval,
  format,
} from 'date-fns'

import { MonitoringByMonthDataView } from 'entities/prices'
import { useGetAllActivityAreas } from 'entities/referenceBooks'
import { convertDateForAPI } from 'shared/utils'
import { DatePickerField, FormInputBlock, SelectInputForm } from 'shared/ui'

import { useGetMonitoringByMonth } from './MonitoringByZoneMothForm'
import { useGetMonitoringByMonthService } from '../models/monitoringByMonth'

// misc
import { HiOutlineTableCells } from 'react-icons/hi2'
import moment from 'moment'

type GeneratedData = {
  month: string
  gs_activity_areas_id: string
}

const generateData = (
  periodFrom: Date,
  periodTo: Date,
  gsActivityAreasId: string,
): GeneratedData[] => {
  const monthsInRange = eachMonthOfInterval({
    start: periodFrom,
    end: periodTo,
  })

  return monthsInRange.map((monthDate) => ({
    month: format(monthDate, 'yyyy-MM-dd'),
    gs_activity_areas_id: gsActivityAreasId,
  }))
}

export const MonitoringByMoth = (): JSX.Element => {
  const [fewPeriods, setFewPeriods] = useState<boolean>(false)
  const [fetchEnabled, setFetchEnabled] = useState<{
    enabled: boolean
  }>({
    enabled: false,
  })

  const { activityAreasData } = useGetAllActivityAreas({
    enabled: true,
  })

  const {
    register,
    handleSubmit,
    errors,
    reset,
    isDirty,
    watchedFields,
    control,
    setValue,
    setError,
    watch,
    isValid,
  } = useGetMonitoringByMonth()

  const periodFrom = watch('period_from')
  const firstDayOfLastMonth = startOfMonth(subMonths(new Date(), 1))
  const oneMonthLater = addMonths(periodFrom, 1)

  const resetFetchState = () =>
    setFetchEnabled({
      enabled: false,
    })

  useEffect(() => {
    if (fewPeriods) setValue('period_to', oneMonthLater)
    else setValue('period_to', undefined)
  }, [fewPeriods])

  useEffect(() => {
    reset({
      period_from: firstDayOfLastMonth,
    })
  }, [])

  const generatedData = useMemo(() => {
    if (!watchedFields.period_from || !watchedFields.gs_activity_areas_id)
      return []

    if (fewPeriods && watchedFields.period_to) {
      return generateData(
        watchedFields.period_from,
        watchedFields.period_to,
        watchedFields.gs_activity_areas_id,
      )
    }

    return [
      {
        month: convertDateForAPI(watchedFields.period_from),
        gs_activity_areas_id: watchedFields.gs_activity_areas_id,
      },
    ]
  }, [
    watchedFields.period_from,
    watchedFields.period_to,
    watchedFields.gs_activity_areas_id,
    fewPeriods,
  ])

  const { data, isLoading, isFetching, refetch } =
    useGetMonitoringByMonthService({
      enabled: fetchEnabled.enabled,
      data: generatedData,
      date_period: fewPeriods
        ? `${convertDateForAPI(watchedFields.period_from)}-${convertDateForAPI(
            watchedFields.period_to,
          )}`
        : `${convertDateForAPI(watchedFields.period_from)}`,
      gs_activity_areas_id: watchedFields.gs_activity_areas_id,
      successAction: () => resetFetchState(),
      errorAction: () => resetFetchState(),
    })

  const commonInputProps = useMemo(
    () => ({
      control,
      register,
      errors,
      watchedFields,
      size: 'sm' as 'sm',
      smallErrorTextInside: true,
      isRequired: true,
    }),
    [register, errors, watchedFields, control],
  )

  const handleFetchData = () => refetch()

  const handleSwitchChange = (checked: boolean) => setFewPeriods(checked)

  const MonitoringByMonthDataViewProps = {
    data,
  }

  return (
    <Box p="2">
      <form>
        <Box mt={'15px'}>
          <Flex flexDirection={'column'}>
            <Box w={'480px'}>
              <FormInputBlock
                titleWidth={'180px'}
                allowEdit={true}
                edit
                title={'Зона деятельности'}
              >
                <SelectInputForm
                  placeholder="Выберите зону  зон"
                  getOptionValue={(option) => (option ? option.value : '')}
                  name="gs_activity_areas_id"
                  data={activityAreasData}
                  isClearable={true}
                  {...commonInputProps}
                />
              </FormInputBlock>
            </Box>

            <Flex mt={'15px'}>
              <FormInputBlock
                titleWidth={'180px'}
                allowEdit={true}
                title={'Период'}
                edit={true}
              >
                <DatePickerField
                  isReadOnly
                  setInitialDate
                  format="MM.yyyy"
                  maxDate={
                    watchedFields?.period_to
                      ? subMonths(
                          new Date(
                            moment(
                              watchedFields.period_to,
                              'DD.MM.yyyy',
                            ).toDate(),
                          ),
                          1,
                        )
                      : undefined
                  }
                  showMonthYearPicker
                  name={'period_from'}
                  placeholder={'Дата от'}
                  type={'text'}
                  theme="monthPicker"
                  {...commonInputProps}
                />
                {!!watchedFields.period_to && (
                  <DatePickerField
                    isReadOnly
                    format="MM.yyyy"
                    minDate={
                      watchedFields?.period_from
                        ? addMonths(
                            new Date(
                              moment(
                                watchedFields.period_from,
                                'DD.MM.yyyy',
                              ).toDate(),
                            ),
                            1,
                          )
                        : undefined
                    }
                    showMonthYearPicker
                    name={'period_to'}
                    placeholder={'Дата по'}
                    type={'text'}
                    theme="monthPicker"
                    {...commonInputProps}
                  />
                )}
              </FormInputBlock>
            </Flex>

            <Box m={'8px'} />
            <Flex alignItems={'center'}>
              <FormLabel
                fontSize={'14px'}
                color={'gray.500'}
                fontWeight={'500'}
                mb="0"
                mr={'40px'}
              >
                Несколько периодов
              </FormLabel>
              <Switch
                size={'sm'}
                id={`1`}
                isChecked={fewPeriods}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleSwitchChange(e.target.checked)
                }
              />
            </Flex>
          </Flex>
          <Box mt="20px">
            <Flex>
              <Box>
                <Button
                  w={'135px'}
                  position={'relative'}
                  size={'xs'}
                  color="white"
                  display={'flex'}
                  alignItems={'center'}
                  onClick={handleFetchData}
                  colorScheme="teal"
                  isDisabled={!isValid}
                  isLoading={isLoading || isFetching}
                >
                  <Box fontSize={'18px'} mr={'5px'}>
                    <HiOutlineTableCells />
                  </Box>
                  <Text position={'relative'}>Показать</Text>
                </Button>
              </Box>
            </Flex>
          </Box>
        </Box>
        {data && (
          <Box
            mt="15px"
            p="2"
            border={'1px solid #dfe0eb'}
            borderRadius={'5px'}
          >
            <MonitoringByMonthDataView
              {...(MonitoringByMonthDataViewProps as any)}
            />
          </Box>
        )}
      </form>
    </Box>
  )
}
